/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { UserSimplifiedDTO } from './user-simplified-dto';

/**
 * 
 * @export
 * @interface PayoutDTO
 */
export interface PayoutDTO {
    /**
     * 
     * @type {number}
     * @memberof PayoutDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PayoutDTO
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutDTO
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof PayoutDTO
     */
    amount: number;
    /**
     * 
     * @type {boolean}
     * @memberof PayoutDTO
     */
    isTransferred: boolean;
    /**
     * 
     * @type {UserSimplifiedDTO}
     * @memberof PayoutDTO
     */
    user: UserSimplifiedDTO;
    /**
     * 
     * @type {string}
     * @memberof PayoutDTO
     */
    status: PayoutDTOStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof PayoutDTO
     */
    type: PayoutDTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof PayoutDTO
     */
    cryptoWalletAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof PayoutDTO
     */
    note?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum PayoutDTOStatusEnum {
    Approved = 'APPROVED',
    Pending = 'PENDING',
    Declined = 'DECLINED'
}
/**
    * @export
    * @enum {string}
    */
export enum PayoutDTOTypeEnum {
    Bonus = 'BONUS',
    Profit = 'PROFIT',
    Deposit = 'DEPOSIT',
    Crypto = 'CRYPTO'
}



