/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { TwoFANumberPair } from '../model';
// @ts-ignore
import { ValidateCodeRequest } from '../model';
// @ts-ignore
import { ValidationResponse } from '../model';
/**
 * Security2FaRestAdapterApi - axios parameter creator
 * @export
 */
export const Security2FaRestAdapterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate: async (code: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'code' is not null or undefined
            assertParamExists('activate', 'code', code)
            const localVarPath = `/google-authenticator/activate-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (code !== undefined) {
                localVarQueryParameter['code'] = code;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disable2fa: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/google-authenticator/disable-2fa`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateGrCode: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/google-authenticator/generate-qr-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateKeyCode: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/google-authenticator/generate-key-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ValidateCodeRequest} validateCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCode: async (validateCodeRequest: ValidateCodeRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'validateCodeRequest' is not null or undefined
            assertParamExists('validateCode', 'validateCodeRequest', validateCodeRequest)
            const localVarPath = `/google-authenticator/validate-code`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(validateCodeRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * Security2FaRestAdapterApi - functional programming interface
 * @export
 */
export const Security2FaRestAdapterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = Security2FaRestAdapterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activate(code: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activate(code, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async disable2fa(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.disable2fa(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateGrCode(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateGrCode(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generateKeyCode(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TwoFANumberPair>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generateKeyCode(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ValidateCodeRequest} validateCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async validateCode(validateCodeRequest: ValidateCodeRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidationResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.validateCode(validateCodeRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * Security2FaRestAdapterApi - factory interface
 * @export
 */
export const Security2FaRestAdapterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = Security2FaRestAdapterApiFp(configuration)
    return {
        /**
         * 
         * @param {string} code 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activate(code: string, options?: any): AxiosPromise<object> {
            return localVarFp.activate(code, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        disable2fa(options?: any): AxiosPromise<void> {
            return localVarFp.disable2fa(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateGrCode(options?: any): AxiosPromise<string> {
            return localVarFp.generateGrCode(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generateKeyCode(options?: any): AxiosPromise<TwoFANumberPair> {
            return localVarFp.generateKeyCode(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ValidateCodeRequest} validateCodeRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateCode(validateCodeRequest: ValidateCodeRequest, options?: any): AxiosPromise<ValidationResponse> {
            return localVarFp.validateCode(validateCodeRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for activate operation in Security2FaRestAdapterApi.
 * @export
 * @interface Security2FaRestAdapterApiActivateRequest
 */
export interface Security2FaRestAdapterApiActivateRequest {
    /**
     * 
     * @type {string}
     * @memberof Security2FaRestAdapterApiActivate
     */
    readonly code: string
}

/**
 * Request parameters for validateCode operation in Security2FaRestAdapterApi.
 * @export
 * @interface Security2FaRestAdapterApiValidateCodeRequest
 */
export interface Security2FaRestAdapterApiValidateCodeRequest {
    /**
     * 
     * @type {ValidateCodeRequest}
     * @memberof Security2FaRestAdapterApiValidateCode
     */
    readonly validateCodeRequest: ValidateCodeRequest
}

/**
 * Security2FaRestAdapterApi - object-oriented interface
 * @export
 * @class Security2FaRestAdapterApi
 * @extends {BaseAPI}
 */
export class Security2FaRestAdapterApi extends BaseAPI {
    /**
     * 
     * @param {Security2FaRestAdapterApiActivateRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Security2FaRestAdapterApi
     */
    public activate(requestParameters: Security2FaRestAdapterApiActivateRequest, options?: any) {
        return Security2FaRestAdapterApiFp(this.configuration).activate(requestParameters.code, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Security2FaRestAdapterApi
     */
    public disable2fa(options?: any) {
        return Security2FaRestAdapterApiFp(this.configuration).disable2fa(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Security2FaRestAdapterApi
     */
    public generateGrCode(options?: any) {
        return Security2FaRestAdapterApiFp(this.configuration).generateGrCode(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Security2FaRestAdapterApi
     */
    public generateKeyCode(options?: any) {
        return Security2FaRestAdapterApiFp(this.configuration).generateKeyCode(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Security2FaRestAdapterApiValidateCodeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof Security2FaRestAdapterApi
     */
    public validateCode(requestParameters: Security2FaRestAdapterApiValidateCodeRequest, options?: any) {
        return Security2FaRestAdapterApiFp(this.configuration).validateCode(requestParameters.validateCodeRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
