import { getAuthConfiguration } from './auth';
import {
  ManageSuccessorRestAdapterApi,
  ManageSuccessorRestAdapterApiUpdateSuccessorRequest,
  UserSuccessorResponse,
} from './generated/client';

export const loadSuccessor = async (): Promise<UserSuccessorResponse> => {
  const conf = getAuthConfiguration();
  const api = new ManageSuccessorRestAdapterApi(conf);

  return (await api.loadUserSuccessor()).data;
};

export const updateSuccessor = async (
  requestParameters: ManageSuccessorRestAdapterApiUpdateSuccessorRequest
): Promise<UserSuccessorResponse> => {
  const conf = getAuthConfiguration();
  const api = new ManageSuccessorRestAdapterApi(conf);

  return (await api.updateSuccessor(requestParameters)).data;
};
