/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { UserSimplifiedDTO } from './user-simplified-dto';

/**
 * 
 * @export
 * @interface PaymentDTO
 */
export interface PaymentDTO {
    /**
     * 
     * @type {number}
     * @memberof PaymentDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentDTO
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof PaymentDTO
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof PaymentDTO
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof PaymentDTO
     */
    type: PaymentDTOTypeEnum;
    /**
     * 
     * @type {UserSimplifiedDTO}
     * @memberof PaymentDTO
     */
    user: UserSimplifiedDTO;
}

/**
    * @export
    * @enum {string}
    */
export enum PaymentDTOTypeEnum {
    Invested = 'INVESTED',
    Reinvested = 'REINVESTED',
    Assigned = 'ASSIGNED'
}



