// @mui
import { LoadingButton } from '@mui/lab';
import { Alert, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/hooks/useAuth';
import * as Yup from 'yup';
import useIsMountedRef from '../../../hooks/useIsMountedRef';

// ----------------------------------------------------------------------

type InitialValues = {
  code: string;
  afterSubmit?: string;
};

type Props = {
  username: string;
  password: string;
};

export default function Security2FaForm({ username, password }: Props) {
  const { t } = useTranslation();
  const { login } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();

  const ResetPasswordSchema = Yup.object().shape({
    code: Yup.string().required(t('yup.authCode')),
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      code: '',
    },
    validationSchema: ResetPasswordSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await login(username, password, values.code);
        if (isMountedRef.current) {
          enqueueSnackbar(t('snackbar.loginSuccess'));
          setSubmitting(false);
        }
      } catch (error) {
        console.error(error);
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.response.data.message });
        }
      }
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <DialogTitle>{t('title.securityCode')}</DialogTitle>
        <DialogContent>
          <Stack mt={3} spacing={3} sx={{ minWidth: 300 }}>
            {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
            <TextField
              fullWidth
              {...getFieldProps('code')}
              type="code"
              label={t('field.code')}
              error={Boolean(touched.code && errors.code)}
              helperText={touched.code && errors.code}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            {t('common.login')}
          </LoadingButton>
        </DialogActions>
      </Form>
    </FormikProvider>
  );
}
