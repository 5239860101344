/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AbstractPageableResponseVipIndexActivityDTO } from '../model';
// @ts-ignore
import { AbstractPageableResponseVipIndexTransactionDTO } from '../model';
// @ts-ignore
import { CryptoDataResponse } from '../model';
// @ts-ignore
import { VipIndexDistributionResponse } from '../model';
/**
 * ManageVipIndexRestAdapterApi - axios parameter creator
 * @export
 */
export const ManageVipIndexRestAdapterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {'INVESTED' | 'WITHDRAWN'} type 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listByType: async (type: 'INVESTED' | 'WITHDRAWN', page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'type' is not null or undefined
            assertParamExists('listByType', 'type', type)
            const localVarPath = `/vip-index/load-activity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadCryptoData: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/vip-index/load-crypto-data`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadVipIndexDistribution: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/vip-index/load-vip-index-distribution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadVipIndexTransactionList: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/vip-index/load-vip-index-transaction-list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManageVipIndexRestAdapterApi - functional programming interface
 * @export
 */
export const ManageVipIndexRestAdapterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManageVipIndexRestAdapterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {'INVESTED' | 'WITHDRAWN'} type 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listByType(type: 'INVESTED' | 'WITHDRAWN', page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractPageableResponseVipIndexActivityDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listByType(type, page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadCryptoData(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CryptoDataResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadCryptoData(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadVipIndexDistribution(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VipIndexDistributionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadVipIndexDistribution(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadVipIndexTransactionList(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractPageableResponseVipIndexTransactionDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadVipIndexTransactionList(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManageVipIndexRestAdapterApi - factory interface
 * @export
 */
export const ManageVipIndexRestAdapterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManageVipIndexRestAdapterApiFp(configuration)
    return {
        /**
         * 
         * @param {'INVESTED' | 'WITHDRAWN'} type 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listByType(type: 'INVESTED' | 'WITHDRAWN', page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<AbstractPageableResponseVipIndexActivityDTO> {
            return localVarFp.listByType(type, page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadCryptoData(options?: any): AxiosPromise<CryptoDataResponse> {
            return localVarFp.loadCryptoData(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadVipIndexDistribution(options?: any): AxiosPromise<VipIndexDistributionResponse> {
            return localVarFp.loadVipIndexDistribution(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadVipIndexTransactionList(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<AbstractPageableResponseVipIndexTransactionDTO> {
            return localVarFp.loadVipIndexTransactionList(page, size, sort, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for listByType operation in ManageVipIndexRestAdapterApi.
 * @export
 * @interface ManageVipIndexRestAdapterApiListByTypeRequest
 */
export interface ManageVipIndexRestAdapterApiListByTypeRequest {
    /**
     * 
     * @type {'INVESTED' | 'WITHDRAWN'}
     * @memberof ManageVipIndexRestAdapterApiListByType
     */
    readonly type: 'INVESTED' | 'WITHDRAWN'

    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof ManageVipIndexRestAdapterApiListByType
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof ManageVipIndexRestAdapterApiListByType
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof ManageVipIndexRestAdapterApiListByType
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for loadVipIndexTransactionList operation in ManageVipIndexRestAdapterApi.
 * @export
 * @interface ManageVipIndexRestAdapterApiLoadVipIndexTransactionListRequest
 */
export interface ManageVipIndexRestAdapterApiLoadVipIndexTransactionListRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof ManageVipIndexRestAdapterApiLoadVipIndexTransactionList
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof ManageVipIndexRestAdapterApiLoadVipIndexTransactionList
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof ManageVipIndexRestAdapterApiLoadVipIndexTransactionList
     */
    readonly sort?: Array<string>
}

/**
 * ManageVipIndexRestAdapterApi - object-oriented interface
 * @export
 * @class ManageVipIndexRestAdapterApi
 * @extends {BaseAPI}
 */
export class ManageVipIndexRestAdapterApi extends BaseAPI {
    /**
     * 
     * @param {ManageVipIndexRestAdapterApiListByTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManageVipIndexRestAdapterApi
     */
    public listByType(requestParameters: ManageVipIndexRestAdapterApiListByTypeRequest, options?: any) {
        return ManageVipIndexRestAdapterApiFp(this.configuration).listByType(requestParameters.type, requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManageVipIndexRestAdapterApi
     */
    public loadCryptoData(options?: any) {
        return ManageVipIndexRestAdapterApiFp(this.configuration).loadCryptoData(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManageVipIndexRestAdapterApi
     */
    public loadVipIndexDistribution(options?: any) {
        return ManageVipIndexRestAdapterApiFp(this.configuration).loadVipIndexDistribution(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManageVipIndexRestAdapterApiLoadVipIndexTransactionListRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManageVipIndexRestAdapterApi
     */
    public loadVipIndexTransactionList(requestParameters: ManageVipIndexRestAdapterApiLoadVipIndexTransactionListRequest = {}, options?: any) {
        return ManageVipIndexRestAdapterApiFp(this.configuration).loadVipIndexTransactionList(requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }
}
