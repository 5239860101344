import { Box, Container, Grid, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { MotionInView, varFade } from '../../components/animate';

// @mui
// hooks
// components
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(15, 0),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  marginBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
    marginBottom: 0,
  },
}));

// ----------------------------------------------------------------------

export default function Benefits() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <RootStyle>
      <Container>
      <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={{ xs: 8, md: 3 }}
        >
          <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
            <Box
              sx={{
                textAlign: 'left',
                mb: { xs: 1, md: 4 },
              }}
            >
              <MotionInView variants={varFade().inUp}>
                <Typography variant="h2" sx={{
                  color: 'common.black'
                }}>{t('landingPage.benefits.title')}
                </Typography>
              </MotionInView>

              <MotionInView variants={varFade().inDown}>
                <div className='horizontal-line' />
                <Typography component="div" variant="overline" sx={{ mb: 2, color: 'text.disabled' }} />
              </MotionInView>

            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={4}>
          <Grid item xs={12} md={4} dir="ltr">
            <Box
              sx={{
                textAlign: 'left',
                mb: { xs: 1, md: 5 },
              }}
            >
              <MotionInView variants={varFade().inUp}>
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <div style={{ margin: '16px' }}>
                    <Box component="img" src="/images/all-time-support.svg" />
                    </div>
                  <Typography variant="h4" sx={{
                    color: 'common.black'
                  }}>{t('landingPage.benefits.supportTitle')}
                  </Typography>
                </div>
              </MotionInView>

              <MotionInView variants={varFade().inDown}>
                <Typography
                  sx={{
                    mt: 5,
                    color: isLight ? 'text.secondary' : 'common.white',
                  }}
                >
                  {t('landingPage.benefits.supportDescription')}
                </Typography>
              </MotionInView>

            </Box>
          </Grid>

          <Grid item xs={12} md={4} dir="ltr">
            <Box
              sx={{
                textAlign: 'left',
                mb: { xs: 1, md: 5 },
              }}
            >
              <MotionInView variants={varFade().inUp}>
              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  <div style={{ margin: '16px' }}>
                  <Box component="img" src="/images/security-safety.svg" sx={{ width: 50, height: 50 }} />
                    </div>
                  <Typography variant="h4" sx={{
                    color: 'common.black'
                  }}>{t('landingPage.benefits.securityTitle')}
                  </Typography>
                </div>
              </MotionInView>

              <MotionInView variants={varFade().inDown}>
                <Typography
                  sx={{
                    mt: 5,
                    color: isLight ? 'text.secondary' : 'common.white',
                  }}
                >
                  {t('landingPage.benefits.securityDescription')}
                </Typography>
              </MotionInView>

            </Box>
          </Grid>

          <Grid item xs={12} md={4} dir="ltr">
            <Box
              sx={{
                textAlign: 'left',
                mb: { xs: 5, md: 5 },
              }}
            >
              <MotionInView variants={varFade().inUp}>
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <div style={{ margin: '16px' }}>
                  <Box component="img" src="/images/benefits-rocket.svg" sx={{ width: 50, height: 50 }} />
                  </div>
                  <Typography variant="h4" sx={{
                    color: 'common.black'
                  }}>{t('landingPage.benefits.dedicatedTitle')}
                  </Typography>
                </div>
              </MotionInView>

              <MotionInView variants={varFade().inDown}>
                <Typography
                  sx={{
                    mt: 5,
                    color: isLight ? 'text.secondary' : 'common.white',
                  }}
                >
                  {t('landingPage.benefits.dedicatedDescription')}
                </Typography>
              </MotionInView>

            </Box>
          </Grid>

        </Grid>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4} dir="ltr">
            <Box
              sx={{
                textAlign: 'left',
                mb: { xs: 1, md: 5 },
              }}
            >
              <MotionInView variants={varFade().inUp}>
              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <div style={{ margin: '16px' }}>
                  <Box component="img" src="/images/news-education.svg" sx={{ width: 50, height: 50 }} />
                  </div>
                  <Typography variant="h4" sx={{
                    color: 'common.black'
                  }}>{t('landingPage.benefits.newsTitle')}
                  </Typography>
                </div>
              </MotionInView>

              <MotionInView variants={varFade().inDown}>
                <Typography
                  sx={{
                    mt: 5,
                    color: isLight ? 'text.secondary' : 'common.white',
                  }}
                >
                  {t('landingPage.benefits.newsDescription')}
                </Typography>
              </MotionInView>

            </Box>
          </Grid>

          <Grid item xs={12} md={4} dir="ltr">
            <Box
              sx={{
                textAlign: 'left',
                mb: { xs: 1, md: 5 },
              }}
            >
              <MotionInView variants={varFade().inUp}>
              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <div style={{ margin: '16px' }}>
                  <Box component="img" src="/images/transparency.svg" sx={{ width: 50, height: 50 }} />
                  </div>
                  <Typography variant="h4" sx={{
                    color: 'common.black'
                  }}>{t('landingPage.benefits.transparencyTitle')}
                  </Typography>
                </div>
              </MotionInView>

              <MotionInView variants={varFade().inDown}>
                <Typography
                  sx={{
                    mt: 5,
                    color: isLight ? 'text.secondary' : 'common.white',
                  }}
                >
                  {t('landingPage.benefits.transparencyDescription')}
                </Typography>
              </MotionInView>

            </Box>
          </Grid>

          <Grid item xs={12} md={4} dir="ltr">
            <Box
              sx={{
                textAlign: 'left',
                mb: { xs: 10, md: 5 },
              }}
            >
              <MotionInView variants={varFade().inUp}>
              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <div style={{ margin: '16px' }}>
                  <Box component="img" src="/images/easy-platform.svg" sx={{ width: 50, height: 50 }} />
                  </div>
                  <Typography variant="h4" sx={{
                    color: 'common.black'
                  }}>{t('landingPage.benefits.easyTitle')}
                  </Typography>
                </div>
              </MotionInView>

              <MotionInView variants={varFade().inDown}>
                <Typography
                  sx={{
                    mt: 5,
                    color: isLight ? 'text.secondary' : 'common.white',
                  }}
                >
                  {t('landingPage.benefits.easyDescription')}
                </Typography>
              </MotionInView>

            </Box>
          </Grid>

        </Grid>

        <Grid container spacing={5}>
          <Grid item xs={12} md={4} dir="ltr">
            <Box
              sx={{
                textAlign: 'left',
                mb: { xs: 1, md: 5 },
              }}
            >
              <MotionInView variants={varFade().inUp}>
              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <div style={{ margin: '16px' }}>
                  <Box component="img" src="/images/cash-benefits.svg" sx={{ width: 50, height: 50 }} />
                  </div>
                  <Typography variant="h4" sx={{
                    color: 'common.black'
                  }}>{t('landingPage.benefits.withdrawalTitle')}
                  </Typography>
                </div>
              </MotionInView>

              <MotionInView variants={varFade().inDown}>
                <Typography
                  sx={{
                    mt: 5,
                    color: isLight ? 'text.secondary' : 'common.white',
                  }}
                >
                  {t('landingPage.benefits.withdrawalDescription')}
                </Typography>
              </MotionInView>

            </Box>
          </Grid>

          <Grid item xs={12} md={4} dir="ltr">
            <Box
              sx={{
                textAlign: 'left',
                mb: { xs: 1, md: 5 },
              }}
            >
              <MotionInView variants={varFade().inUp}>
              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <div style={{ margin: '16px' }}>
                  <Box component="img" src="/images/trending-up-sharp.svg" sx={{ width: 50, height: 50 }} />
                  </div>
                  <Typography variant="h4" sx={{
                    color: 'common.black'
                  }}>{t('landingPage.benefits.diversifiedTitle')}
                  </Typography>
                </div>
              </MotionInView>

              <MotionInView variants={varFade().inDown}>
                <Typography
                  sx={{
                    mt: 5,
                    color: isLight ? 'text.secondary' : 'common.white',
                  }}
                >
                  {t('landingPage.benefits.diversifiedDescription')}
                </Typography>
              </MotionInView>

            </Box>
          </Grid>

          <Grid item xs={12} md={4} dir="ltr">
            <Box
              sx={{
                textAlign: 'left',
                mb: { xs: 1, md: 5 },
              }}
            >
              <MotionInView variants={varFade().inUp}>
              <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                <div style={{ margin: '16px' }}>
                  <Box component="img" src="/images/shield-checkmark.svg" sx={{ width: 50, height: 50 }} />
                  </div>
                  <Typography variant="h4" sx={{
                    color: 'common.black'
                  }}>{t('landingPage.benefits.safetyTitle')}
                  </Typography>
                </div>
              </MotionInView>

              <MotionInView variants={varFade().inDown}>
                <Typography
                  sx={{
                    mt: 5,
                    color: isLight ? 'text.secondary' : 'common.white',
                  }}
                >
                  {t('landingPage.benefits.safetyDescription')}
                </Typography>
              </MotionInView>

            </Box>
          </Grid>

        </Grid>
      </Container>
    </RootStyle>
  );
}
