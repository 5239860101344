import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TreeView from '@mui/lab/TreeView';
import { Button, Table, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { AffiliateNetworkType, loadAffiliateSearch, loadLineAffiliate } from 'src/api';
import { AffiliateNetworkNodeSimplifiedDTO } from 'src/api/generated/common';
import Iconify from 'src/components/Iconify';
import useAuth from 'src/hooks/useAuth';
import { PATH_DASHBOARD } from 'src/routes/paths';
import AffiliateSearchBar from './AffiliateSearchBar';
import { StyledTreeItem } from './AffiliateTreeItem';

export default function AffiliateTree() {
  const { user } = useAuth();
  const { t } = useTranslation();

  const [affiliateList, setAffiliateList] = useState<AffiliateNetworkType[]>([]);
  const [selectedUser, setSelectedUser] = useState<AffiliateNetworkType>();

  const loadAffiliate = async (id: number, line = 0) => {
    const response = await loadLineAffiliate({ userId: id, line });
    const data = affiliateList.map((item) =>
      updateAffiliate(id, item, response as AffiliateNetworkType[])
    );
    setAffiliateList([...data]);
  };

  const loadTop = async (userId: number) => {
    const response = await loadAffiliateSearch({ affiliateId: userId });
    if (response && response.length > 0)
      setSelectedUser({ ...response[response.length - 1], items: [] });
  };

  useEffect(() => {
    if (!user) return;

    loadTop(user.id);
  }, [user]);

  useEffect(() => {
    if (selectedUser) {
      loadLineAffiliate({ userId: selectedUser.id, line: 0 }).then((res) => {
        setAffiliateList(res as AffiliateNetworkType[]);
      });
    }
  }, [selectedUser]);

  const updateAffiliate = (
    id: number,
    item: AffiliateNetworkType,
    items: AffiliateNetworkType[]
  ): AffiliateNetworkType => {
    if (item.id === id) {
      if (item.items) {
        return item;
      }
      return { ...item, items };
    }
    if (!item?.items) return item;
    const affiliateItems = item.items.map((i) => updateAffiliate(id, i, items));
    return { ...item, items: affiliateItems };
  };

  const handleSearch = async (userValue?: AffiliateNetworkNodeSimplifiedDTO) => {
    if (!userValue) {
      loadTop(user!.id);
    } else {
      loadTop(userValue.id);
    }
  };

  const renderAffiliate = (items?: AffiliateNetworkType[]) =>
    items?.map((item) => (
      <StyledTreeItem
        key={item.id}
        nodeId={`${item.id}`}
        item={item}
        onClick={() => loadAffiliate(item.id, item.line)}
      >
        <>
          {item.items ? (
            renderAffiliate(item.items)
          ) : (
            <>
              {new Array(item.members).map((i) => (
                <StyledTreeItem key={i} nodeId={`${i}`} item={i} />
              ))}
            </>
          )}
        </>
      </StyledTreeItem>
    ));

  return (
    <>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t('affiliateTree.name')}</TableCell>
              <TableCell>{t('affiliateTree.line')}</TableCell>
              <TableCell>{t('affiliateTree.clients')}</TableCell>
              <TableCell>{t('affiliateTree.bonuses')}</TableCell>
              <TableCell>{t('affiliateTree.income')}</TableCell>
              <TableCell align="right">
                <Button
                  variant="contained"
                  component={RouterLink}
                  to={PATH_DASHBOARD.invite.root}
                  startIcon={<Iconify icon={'eva:plus-fill'} />}
                >
                  {t('common.invite')}
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <AffiliateSearchBar onSearch={handleSearch} />
        </Table>
      </TableContainer>
      <TreeView
        aria-label="gmail"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        sx={{ pr: 2 }}
      >
        {selectedUser && (
          <StyledTreeItem
            key={selectedUser.id}
            nodeId={`${selectedUser.id}`}
            item={selectedUser}
            onClick={() => loadAffiliate(selectedUser.id, selectedUser.line)}
          >
            {renderAffiliate(affiliateList)}
          </StyledTreeItem>
        )}
      </TreeView>
    </>
  );
}
