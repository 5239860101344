// @mui
import { Dialog, DialogTitle } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useAuth from 'src/hooks/useAuth';
// utils
import ActualizePersonalDataForm from './ActualizePersonalDataForm';

// ----------------------------------------------------------------------

export default function ActualizePersonalDataModal() {
  const { t } = useTranslation();
  const { user } = useAuth();

  return (
    user && (
      <Dialog open={!user?.isPersonalDataActualized}>
        <DialogTitle>{t('title.actualizeData')}</DialogTitle>
        <ActualizePersonalDataForm />
      </Dialog>
    )
  );
}
