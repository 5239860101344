/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface PositionTypeData
 */
export interface PositionTypeData {
    /**
     * 
     * @type {string}
     * @memberof PositionTypeData
     */
    type: PositionTypeDataTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof PositionTypeData
     */
    value: number;
}

/**
    * @export
    * @enum {string}
    */
export enum PositionTypeDataTypeEnum {
    Established = 'ESTABLISHED',
    Startup = 'STARTUP',
    Active = 'ACTIVE'
}



