import { getAuthConfiguration } from './auth';
import {
  InboxMessageDTO,
  InboxResponse,
  LoadInboxMessagesRestAdapterApi,
  LoadInboxMessagesRestAdapterApiGetContentRequest,
  LoadInboxMessagesRestAdapterApiLikeInboxByIdRequest,
  LoadInboxMessagesRestAdapterApiListRequest,
  LoadInboxMessagesRestAdapterApiReadInboxByIdRequest,
} from './generated/client';

export const getNewsList = async (
  requestParameters: LoadInboxMessagesRestAdapterApiListRequest
): Promise<InboxResponse> => {
  const conf = getAuthConfiguration();
  const api = new LoadInboxMessagesRestAdapterApi(conf);

  return (await api.list(requestParameters)).data;
};

export const readNews = async (
  requestParameters: LoadInboxMessagesRestAdapterApiReadInboxByIdRequest
): Promise<object> => {
  const conf = getAuthConfiguration();
  const api = new LoadInboxMessagesRestAdapterApi(conf);

  return (await api.readInboxById(requestParameters)).data;
};

export const likeNews = async (
  requestParameters: LoadInboxMessagesRestAdapterApiLikeInboxByIdRequest
): Promise<object> => {
  const conf = getAuthConfiguration();
  const api = new LoadInboxMessagesRestAdapterApi(conf);

  return (await api.likeInboxById(requestParameters)).data;
};

export const getNewsContent = async (
  requestParameters: LoadInboxMessagesRestAdapterApiGetContentRequest
): Promise<InboxMessageDTO> => {
  const conf = getAuthConfiguration();
  const api = new LoadInboxMessagesRestAdapterApi(conf);

  return (await api.getContent(requestParameters)).data;
};
