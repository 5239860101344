/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { CryptocurrencyDTO } from './cryptocurrency-dto';

/**
 * 
 * @export
 * @interface ShortIndexDTO
 */
export interface ShortIndexDTO {
    /**
     * 
     * @type {number}
     * @memberof ShortIndexDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof ShortIndexDTO
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof ShortIndexDTO
     */
    updatedAt?: string;
    /**
     * 
     * @type {CryptocurrencyDTO}
     * @memberof ShortIndexDTO
     */
    cryptoCurrency: CryptocurrencyDTO;
    /**
     * 
     * @type {string}
     * @memberof ShortIndexDTO
     */
    openAt: string;
    /**
     * 
     * @type {string}
     * @memberof ShortIndexDTO
     */
    closeAt?: string;
    /**
     * 
     * @type {number}
     * @memberof ShortIndexDTO
     */
    openPrice: number;
    /**
     * 
     * @type {number}
     * @memberof ShortIndexDTO
     */
    closePrice?: number;
    /**
     * 
     * @type {number}
     * @memberof ShortIndexDTO
     */
    percent?: number;
    /**
     * 
     * @type {string}
     * @memberof ShortIndexDTO
     */
    indexStatus: ShortIndexDTOIndexStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof ShortIndexDTO
     */
    profitPercentString?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortIndexDTO
     */
    openPriceString: string;
    /**
     * 
     * @type {string}
     * @memberof ShortIndexDTO
     */
    closePriceString?: string;
    /**
     * 
     * @type {string}
     * @memberof ShortIndexDTO
     */
    percentString?: string;
}

/**
    * @export
    * @enum {string}
    */
export enum ShortIndexDTOIndexStatusEnum {
    Open = 'OPEN',
    Closed = 'CLOSED'
}



