import { Box, Card, Stack, styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { AffiliateNetworkHeadResponse } from 'src/api/generated/common';
import { fCurrency } from 'src/utils/formatNumber';

const StyledText = styled(Typography)({
  textAlign: 'center',
});

type StyledTreeItemProps = {
  data?: AffiliateNetworkHeadResponse;
};

export function AffiliateTotalWidget({ data }: StyledTreeItemProps) {
  const { t } = useTranslation();
  return (
    <Card
      sx={{
        height: '100%',
        p: 1,
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Stack
          direction="column"
          flex={1}
          height="100%"
          alignItems="center"
          justifyContent={'space-around'}
        >
          <StyledText variant="subtitle2">{t('affiliateTree.totalBonus')}</StyledText>

          <StyledText variant="h5">{fCurrency(data?.totalBonus)}</StyledText>
        </Stack>
      </Box>
    </Card>
  );
}
