// @mui
import { Form, FormikProvider, useFormik } from 'formik';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
// hooks
import { DatePicker, LoadingButton } from '@mui/lab';
import { Alert, Stack, TextField } from '@mui/material';
import { useSnackbar } from 'notistack';
import { actualizePersonalData } from 'src/api';
import useAuth from 'src/hooks/useAuth';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import { countries } from 'src/_mock';
import { UpdatePersonalDataSchema } from '../register/yupSchema';
import { setSession } from 'src/utils/jwt';
import { values } from 'lodash';

// ----------------------------------------------------------------------

export type DataInitialValues = {
  username: string;
  countryCode: string;
  birthday: string;
  name: string;
  surname: string;
  phone: string;
  email: string;
  afterSubmit?: string;
};

export type DocumentInitialValues = {
  personalDocument: File | null;
  proofOfAddressDocument: File | null;
  afterSubmit?: string;
};

export default function ActualizePersonalDataForm() {
  const { t } = useTranslation();
  const { user, loadUser } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  const isMountedRef = useIsMountedRef();
  const formikData = useFormik<DataInitialValues>({
    initialValues: {
      username: '',
      countryCode: '',
      birthday: '',
      name: user?.personalData.name || '',
      surname: user?.personalData.surname || '',
      phone: '',
      email: '',
    },
    validationSchema: UpdatePersonalDataSchema,
    onSubmit: async () => {
      try {
        const response = await actualizePersonalData({
          actualizePersonalDataRequest: {
            countryCode: formikData.values.countryCode,
            birthday: moment(formikData.values.birthday).toISOString(),
            name: formikData.values.name,
            username: formikData.values.username,
            surname: formikData.values.surname,
            phone: formikData.values.phone,
            email: formikData.values.email,
          },
        });

        setSession(response.token || null);
        await loadUser();

        if (isMountedRef.current) {
          formikData.setSubmitting(false);
        }
      } catch (error) {
        if (isMountedRef.current) {
          formikData.setFieldError('phone', 'item.message');
          error.response.data.subErrors.forEach((item: any) => {
            formikData.setFieldError(item.field.toString(), item.message);
          });
          formikData.setSubmitting(false);
        }
      }
    },
  });

  const { errors, touched, handleSubmit, isSubmitting, setFieldValue, getFieldProps } = formikData;

  return (
    <>
      <FormikProvider value={formikData}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit} style={{ padding: 20 }}>
          {errors.afterSubmit && <Alert severity="error">{errors.afterSubmit}</Alert>}
          <Stack spacing={3}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
              <TextField
                fullWidth
                label={t('field.firstName')}
                {...getFieldProps('name')}
                error={Boolean(touched.name && errors.name)}
                helperText={touched.name && errors.name}
              />

              <TextField
                fullWidth
                label={t('field.surname')}
                {...getFieldProps('surname')}
                error={Boolean(touched.surname && errors.surname)}
                helperText={touched.surname && errors.surname}
              />
            </Stack>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
              <TextField
                fullWidth
                autoComplete="email"
                type="email"
                label={t('field.email')}
                {...getFieldProps('email')}
                error={Boolean(touched.email && errors.email)}
                helperText={touched.email && errors.email}
              />
              <TextField
                fullWidth
                autoComplete="username"
                label={t('field.username')}
                {...getFieldProps('username')}
                error={Boolean(touched.username && errors.username)}
                helperText={touched.username && errors.username}
              />
            </Stack>

            <DatePicker
              label={t('field.birthday')}
              {...getFieldProps('birthday')}
              onChange={(date) => setFieldValue('birthday', date)}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  error={Boolean(touched.birthday && errors.birthday)}
                  helperText={touched.birthday && errors.birthday}
                />
              )}
              inputFormat="dd/MM/yyyy"
            />

            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 3, sm: 2 }}>
              <TextField
                select
                fullWidth
                label={t('field.country')}
                placeholder={t('field.country')}
                {...getFieldProps('countryCode')}
                SelectProps={{ native: true }}
                error={Boolean(touched.countryCode && errors.countryCode)}
                helperText={touched.countryCode && errors.countryCode}
              >
                <option value="" />
                {countries.map((option) => (
                  <option key={option.code} value={option.code}>
                    {option.label}
                  </option>
                ))}
              </TextField>
              <TextField
                fullWidth
                label={t('field.phoneNumber')}
                {...getFieldProps('phone')}
                error={Boolean(touched.phone && errors.phone)}
                helperText={touched.phone && errors.phone}
              />
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
            >
              {t('common.update')}
            </LoadingButton>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
