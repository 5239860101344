import numeral from 'numeral';

// ----------------------------------------------------------------------

export function fCurrency(number: string | number | undefined) {
  if (!number) return '$0,0';
  return numeral(number).format(Number.isInteger(number) ? '$0,0' : '$0,0.00');
}

export function fPercent(number: number | string | undefined, countAfter = 1) {
  if (!number) return `0.${'0'.repeat(countAfter)}%`;
  return numeral(+number / 100).format(`0.${'0'.repeat(countAfter)}%`);
}
export function fNumber(number: string | number) {
  return numeral(number).format();
}

export function fShortenNumber(number: string | number) {
  return numeral(number).format('0.00a').replace('.00', '');
}

export function fData(number: string | number) {
  return numeral(number).format('0.0 b');
}
