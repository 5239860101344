import TreeItem, { treeItemClasses, TreeItemProps } from '@mui/lab/TreeItem';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { AffiliateNetworkType } from 'src/api';
import { fCurrency } from 'src/utils/formatNumber';
import { AffiliateContent } from './AffilateContent';

declare module 'react' {
  interface CSSProperties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  item?: AffiliateNetworkType;
};

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: '100%',
  paddingLeft: theme.spacing(2),
  //   paddingRight: theme.spacing(2),
  [`& .${treeItemClasses.content}`]: {
    // color: theme.palette.text.secondary,
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      //   backgroundColor: `var(--tree-view-bg-color, ${theme.palette.action.selected})`,
      color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 0,
    [`& .${treeItemClasses.content}`]: {
      paddingLeft: theme.spacing(2),
    },
  },
}));

const LabelStyled = styled('div')(({ theme }) => ({
  color: theme.palette.text.secondary,
  width: '100%',
  height: 40,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingLeft: theme.spacing(3),
  justifyContent: 'space-between',
}));

const LabelItemStyled = styled('div')({
  flex: 1,
});

export function StyledTreeItem({ item, onClick, ...other }: StyledTreeItemProps) {
  return (
    <StyledTreeItemRoot
      ContentComponent={AffiliateContent}
      ContentProps={{
        onLoad: onClick,
      }}
      label={
        <LabelStyled>
          <LabelItemStyled>
            <Typography
              onClick={onClick}
              variant="body2"
              sx={{ fontWeight: 'inherit', flexGrow: 1 }}
            >
              {item?.fullName}
            </Typography>
          </LabelItemStyled>
          <LabelItemStyled sx={{ ml: 2 }}>
            <Typography variant="caption" color="inherit">
              {item?.line}
            </Typography>
          </LabelItemStyled>
          <LabelItemStyled>
            <Typography variant="caption" color="inherit">
              {item?.members}
            </Typography>
          </LabelItemStyled>
          <LabelItemStyled>
            <Typography variant="caption" color="inherit">
              {fCurrency(item?.bonuses)}
            </Typography>
          </LabelItemStyled>
          <LabelItemStyled>
            <Typography variant="caption" color="inherit">
              {fCurrency(item?.invested)}
            </Typography>
          </LabelItemStyled>
        </LabelStyled>
      }
      {...other}
    />
  );
}
