/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CurrentBalanceHistoryDataPoint, PaymentHistoryDataPoint } from '../model';
/**
 * CalculateBalanceRestAdapterApi - axios parameter creator
 * @export
 */
export const CalculateBalanceRestAdapterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBalanceHistory: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('loadBalanceHistory', 'id', id)
            const localVarPath = `/load-balance-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBalanceHistoryByTrades: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('loadBalanceHistoryByTrades', 'id', id)
            const localVarPath = `/load-balance-history-by-trades`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadCurrentBalance: async (id: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('loadCurrentBalance', 'id', id)
            const localVarPath = `/load-current-balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CalculateBalanceRestAdapterApi - functional programming interface
 * @export
 */
export const CalculateBalanceRestAdapterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CalculateBalanceRestAdapterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadBalanceHistory(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: CurrentBalanceHistoryDataPoint; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadBalanceHistory(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadBalanceHistoryByTrades(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: PaymentHistoryDataPoint; }; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadBalanceHistoryByTrades(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadCurrentBalance(id: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentBalanceHistoryDataPoint>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadCurrentBalance(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CalculateBalanceRestAdapterApi - factory interface
 * @export
 */
export const CalculateBalanceRestAdapterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CalculateBalanceRestAdapterApiFp(configuration)
    return {
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBalanceHistory(id: number, options?: any): AxiosPromise<{ [key: string]: CurrentBalanceHistoryDataPoint; }> {
            return localVarFp.loadBalanceHistory(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBalanceHistoryByTrades(id: number, options?: any): AxiosPromise<{ [key: string]: { [key: string]: PaymentHistoryDataPoint; }; }> {
            return localVarFp.loadBalanceHistoryByTrades(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadCurrentBalance(id: number, options?: any): AxiosPromise<CurrentBalanceHistoryDataPoint> {
            return localVarFp.loadCurrentBalance(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for loadBalanceHistory operation in CalculateBalanceRestAdapterApi.
 * @export
 * @interface CalculateBalanceRestAdapterApiLoadBalanceHistoryRequest
 */
export interface CalculateBalanceRestAdapterApiLoadBalanceHistoryRequest {
    /**
     * 
     * @type {number}
     * @memberof CalculateBalanceRestAdapterApiLoadBalanceHistory
     */
    readonly id: number
}

/**
 * Request parameters for loadBalanceHistoryByTrades operation in CalculateBalanceRestAdapterApi.
 * @export
 * @interface CalculateBalanceRestAdapterApiLoadBalanceHistoryByTradesRequest
 */
export interface CalculateBalanceRestAdapterApiLoadBalanceHistoryByTradesRequest {
    /**
     * 
     * @type {number}
     * @memberof CalculateBalanceRestAdapterApiLoadBalanceHistoryByTrades
     */
    readonly id: number
}

/**
 * Request parameters for loadCurrentBalance operation in CalculateBalanceRestAdapterApi.
 * @export
 * @interface CalculateBalanceRestAdapterApiLoadCurrentBalanceRequest
 */
export interface CalculateBalanceRestAdapterApiLoadCurrentBalanceRequest {
    /**
     * 
     * @type {number}
     * @memberof CalculateBalanceRestAdapterApiLoadCurrentBalance
     */
    readonly id: number
}

/**
 * CalculateBalanceRestAdapterApi - object-oriented interface
 * @export
 * @class CalculateBalanceRestAdapterApi
 * @extends {BaseAPI}
 */
export class CalculateBalanceRestAdapterApi extends BaseAPI {
    /**
     * 
     * @param {CalculateBalanceRestAdapterApiLoadBalanceHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculateBalanceRestAdapterApi
     */
    public loadBalanceHistory(requestParameters: CalculateBalanceRestAdapterApiLoadBalanceHistoryRequest, options?: any) {
        return CalculateBalanceRestAdapterApiFp(this.configuration).loadBalanceHistory(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalculateBalanceRestAdapterApiLoadBalanceHistoryByTradesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculateBalanceRestAdapterApi
     */
    public loadBalanceHistoryByTrades(requestParameters: CalculateBalanceRestAdapterApiLoadBalanceHistoryByTradesRequest, options?: any) {
        return CalculateBalanceRestAdapterApiFp(this.configuration).loadBalanceHistoryByTrades(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CalculateBalanceRestAdapterApiLoadCurrentBalanceRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CalculateBalanceRestAdapterApi
     */
    public loadCurrentBalance(requestParameters: CalculateBalanceRestAdapterApiLoadCurrentBalanceRequest, options?: any) {
        return CalculateBalanceRestAdapterApiFp(this.configuration).loadCurrentBalance(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }
}
