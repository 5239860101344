import { Box, Container, Grid, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { MotionInView, varFade } from '../../components/animate';

// @mui
// routes
// components
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(24, 0),
}));

const ContentStyle = styled('div')(({ theme }) => ({
  width: '100%',
  textAlign: 'center',
  marginBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
    marginBottom: 0,
  },
}));

const COMMON = {
  scaleX: 0.86,
  skewY: 8,
  skewX: 0,
  scaleY: 1,
  translateX: 0,
  translateY: 0,
  opacity: 0,
};

const variantScreenLeft = {
  initial: COMMON,
  animate: { ...COMMON, translateX: '-50%', translateY: 40, opacity: 1 },
};
const variantScreenCenter = {
  initial: COMMON,
  animate: { ...COMMON, opacity: 1 },
};
const variantScreenRight = {
  initial: COMMON,
  animate: { ...COMMON, translateX: '50%', translateY: -40, opacity: 1 },
};

const CHART_DATA = [30, 60, 10];

// ----------------------------------------------------------------------

export default function Products() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  return (
    <RootStyle>
      <Container>
        <Grid container spacing={5} justifyContent="center">
          
          <Grid item xs={12} md={4} sx={{ display: 'flex', alignItems: 'center' }}>
            <ContentStyle>
              <Box
                sx={{
                  textAlign: 'left',
                  mb: { xs: 1, md: 25 },
                }}
              >
                <MotionInView variants={varFade().inUp}>
                  <Typography variant="h2" sx={{
                    color: 'common.black'
                  }}>{t('landingPage.products.title')}
                  </Typography>
                </MotionInView>
                <MotionInView variants={varFade().inDown}>
                  <div className='horizontal-line' />
                  <Typography
                    sx={{
                      mt: 5,
                      color: isLight ? 'text.secondary' : 'common.white',
                    }}
                  >{t('landingPage.products.description')}
                  </Typography>
                </MotionInView>
              </Box>
            </ContentStyle>
          </Grid>

          <Grid item xs={12} md={6} dir="ltr">
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                justifyContent: 'center',

              }}
            />
            <Box component="img" src="/images/donut-graph.png" sx={{ mb: { xs: 5 } }} />
          </Grid>
        </Grid>

        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={12} md={4} dir="ltr">
            <Box
              sx={{
                textAlign: 'left',
                mb: { xs: 10, md: 25 },
              }}
            >
              <MotionInView variants={varFade().inUp}>
                <div style={{ display: 'inline-flex' }}>
                  <Box component="img" src="/images/rectangle-active.svg" sx={{ width: 50, height: 50 }} />
                  <Typography variant="h3" sx={{
                    color: 'common.black'
                  }}>{t('landingPage.products.activeIndex')}
                  </Typography>
                </div>
              </MotionInView>
              <MotionInView variants={varFade().inDown}>
                <Typography
                  sx={{
                    mt: 5,
                    color: isLight ? 'text.secondary' : 'common.white',
                  }}
                >{t('landingPage.products.activeIndexDescription')}
                </Typography>
              </MotionInView>
            </Box>
          </Grid>

          <Grid item xs={12} md={4} dir="ltr">
            <Box
              sx={{
                textAlign: 'left',
                mb: { xs: 10, md: 25 },
              }}
            >
              <MotionInView variants={varFade().inUp}>
                <div style={{ display: 'inline-flex' }}>
                  <Box component="img" src="/images/rectangle-established.svg" sx={{ width: 50, height: 50 }} />
                  <Typography variant="h3" sx={{
                    color: 'common.black'
                  }}>{t('landingPage.products.establishedIndex')}
                  </Typography>
                </div>
              </MotionInView>
              <MotionInView variants={varFade().inDown}>
                <Typography
                  sx={{
                    mt: 5,
                    color: isLight ? 'text.secondary' : 'common.white',
                  }}
                >{t('landingPage.products.establishedIndexDescription')}
                </Typography>
              </MotionInView>
            </Box>
          </Grid>

          <Grid item xs={12} md={4} dir="ltr">
            <Box
              sx={{
                textAlign: 'left',
                mb: { xs: 10, md: 25 },
              }}
            >
              <MotionInView variants={varFade().inUp}>
                <div style={{ display: 'inline-flex' }}>
                  <Box component="img" src="/images/rectangle-startup.svg" sx={{ width: 50, height: 50 }} />
                  <Typography variant="h3" sx={{
                    color: 'common.black'
                  }}>{t('landingPage.products.startupIndex')}
                  </Typography>
                </div>
              </MotionInView>
              <MotionInView variants={varFade().inDown}>
                <Typography
                  sx={{
                    mt: 5,
                    color: isLight ? 'text.secondary' : 'common.white',
                  }}
                >{t('landingPage.products.startupIndexDescription')}
                </Typography>
              </MotionInView>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={12} md={6} dir="ltr">
            <Box
              sx={{
                textAlign: 'left',
                mb: { xs: 10, md: 25 },
              }}
            >
              <div style={{ backgroundColor: '#151C24', padding: 24, borderRadius: 10 }}>
                <MotionInView variants={varFade().inUp}>
                  <Typography variant="h3" sx={{
                    color: 'common.white'
                  }}>{t('landingPage.vipWallet.title')}
                  </Typography>
                </MotionInView>
                <MotionInView variants={varFade().inDown}>
                  <Typography
                    sx={{
                      mt: 5,
                    }}
                    style={{ color: 'rgba(255, 255, 255, 0.7)' }}
                  >{t('landingPage.vipWallet.description')}
                  </Typography>
                </MotionInView>
              </div>
            </Box>
          </Grid>

          <Grid item xs={12} md={6} dir="ltr">
            <Box component="img" src="/images/trading-notebook.svg" />
          </Grid>

        </Grid>

        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={12} md={6} dir="ltr">
            <Box component="img" src="/images/watch-on-hand.svg" position={'absolute'} left={'0'} sx={{ bottom: { xs: '56%' } }} />
          </Grid>

          <Grid item xs={12} md={6} dir="ltr">
            <Box
              sx={{
                textAlign: 'left',
                mb: { xs: 10, md: 25 },
              }}
            >
              <div style={{ backgroundColor: 'rgba(21, 28, 36, 0.1)', padding: 24, borderRadius: 10 }}>
                <MotionInView variants={varFade().inUp}>
                  <Typography variant="h3" sx={{
                    color: 'common.black'
                  }}>{t('landingPage.inbox.title')}
                  </Typography>
                </MotionInView>
                <MotionInView variants={varFade().inDown}>
                  <Typography
                    sx={{
                      mt: 5,
                    }}
                    style={{ color: 'rgba(21, 28, 36, 0.7)' }}
                  >{t('landingPage.inbox.description')}
                  </Typography>
                </MotionInView>
              </div>
            </Box>
          </Grid>
        </Grid>

      </Container>
    </RootStyle>
  );
}
