/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { UserSimplifiedDTO } from './user-simplified-dto';

/**
 * 
 * @export
 * @interface VipIndexActivityDTO
 */
export interface VipIndexActivityDTO {
    /**
     * 
     * @type {number}
     * @memberof VipIndexActivityDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof VipIndexActivityDTO
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof VipIndexActivityDTO
     */
    updatedAt?: string;
    /**
     * 
     * @type {UserSimplifiedDTO}
     * @memberof VipIndexActivityDTO
     */
    user: UserSimplifiedDTO;
    /**
     * 
     * @type {number}
     * @memberof VipIndexActivityDTO
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof VipIndexActivityDTO
     */
    type: VipIndexActivityDTOTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof VipIndexActivityDTO
     */
    source?: VipIndexActivityDTOSourceEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum VipIndexActivityDTOTypeEnum {
    Invested = 'INVESTED',
    Withdrawn = 'WITHDRAWN'
}
/**
    * @export
    * @enum {string}
    */
export enum VipIndexActivityDTOSourceEnum {
    Deposit = 'DEPOSIT',
    Bonus = 'BONUS',
    None = 'NONE'
}



