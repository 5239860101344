// @mui
import { Container, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadHeadAffiliate } from 'src/api';
import { AffiliateNetworkHeadResponse } from 'src/api/generated/common';
// components
import Page from 'src/components/Page';
import useSettings from 'src/hooks/useSettings';
import { AffiliateTree } from 'src/sections/@dashboard/affiliate';
import { AffiliateFirstWidget } from 'src/sections/@dashboard/affiliate/AffiliateFirstWidget';
import { AffiliateSecondWidget } from 'src/sections/@dashboard/affiliate/AffiliateSecondWidget';
import { AffiliateTotalWidget } from 'src/sections/@dashboard/affiliate/AffiliateTotalWidget';

// ----------------------------------------------------------------------

export default function AffiliatePage() {
  const { themeStretch } = useSettings();
  const { t } = useTranslation();

  const [data, setData] = useState<AffiliateNetworkHeadResponse>();

  const loadData = async () => {
    try {
      const response = await loadHeadAffiliate();
      setData(response);
    } catch {}
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Page title={t('title.affiliate')}>
      <Container maxWidth={themeStretch ? false : 'xl'}>
        <Grid container spacing={3}>
          <Grid item xs={4}>
            <AffiliateFirstWidget data={data} />
          </Grid>
          <Grid item xs={4}>
            <AffiliateSecondWidget data={data} />
          </Grid>
          <Grid item xs={4}>
            <AffiliateTotalWidget data={data} />
          </Grid>
          <Grid item xs={12}>
            <AffiliateTree />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
