import { Box, Card, CardContent, Link, Stack, Typography } from '@mui/material';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { paramCase } from 'change-case';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { InboxMessageDTO } from 'src/api/generated/client';
import Iconify from 'src/components/Iconify';
// components
import Image from 'src/components/Image';
import Label from 'src/components/Label';
import TextIconLabel from 'src/components/TextIconLabel';
import TextMaxLine from 'src/components/TextMaxLine';
// hooks
import useResponsive from 'src/hooks/useResponsive';
// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
import { fShortenNumber } from 'src/utils/formatNumber';
// utils
import { fDate } from 'src/utils/formatTime';

// ----------------------------------------------------------------------

const OverlayStyle = styled('div')(({ theme }) => ({
  top: 0,
  zIndex: 1,
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundColor: alpha(theme.palette.grey[900], 0.8),
}));

const TextIconLabelStyle = styled(TextIconLabel)(({ theme }) => ({
  cursor: 'pointer',
}));

// ----------------------------------------------------------------------

type Props = {
  post: InboxMessageDTO;
  index?: number;
  handleRate: () => void;
};

export default function NewsPostCard({ post, index, handleRate }: Props) {
  const isDesktop = useResponsive('up', 'md');
  const { previewImage } = post;
  const { t } = useTranslation();

  const latestPost = index === 0 || index === 1 || index === 2;

  if (isDesktop && latestPost) {
    return (
      <Card>
        <NewsPostContent post={post} index={index} handleRate={handleRate} />

        <OverlayStyle />

        <Image
          alt="cover"
          src={`data:image/image/png;base64,${previewImage}`}
          sx={{ height: 360 }}
        />
        {!post.isRead ? (
          <Label
            variant={'filled'}
            color={'error'}
            sx={{ position: 'absolute', top: 10, right: 10, zIndex: 2 }}
          >
            {t('common.new')}
          </Label>
        ) : (
          ''
        )}
      </Card>
    );
  }

  return (
    <Card>
      <Box sx={{ position: 'relative' }}>
        <Image alt="cover" src={`data:image/image/png;base64,${previewImage}`} ratio="4/3" />
      </Box>
      {!post.isRead ? (
        <Label
          variant={'filled'}
          color={'error'}
          sx={{ position: 'absolute', top: 10, right: 10, zIndex: 2 }}
        >
          {t('common.new')}
        </Label>
      ) : (
        ''
      )}

      <NewsPostContent post={post} handleRate={handleRate} />
    </Card>
  );
}

// ----------------------------------------------------------------------

type PostContentProps = {
  post: InboxMessageDTO;
  index?: number;
  handleRate?: () => void;
  link?: string;
};

export function NewsPostContent({ post, link, index, handleRate }: PostContentProps) {
  const isDesktop = useResponsive('up', 'md');

  const linkTo = link || `${PATH_DASHBOARD.news.root}/posts/${post.id}/${paramCase(post.subject)}`;

  const latestPostLarge = index === 0;
  const latestPostSmall = index === 1 || index === 2;

  return (
    <CardContent
      sx={{
        pt: 4.5,
        width: 1,
        ...((latestPostLarge || latestPostSmall) && {
          pt: 0,
          zIndex: 9,
          bottom: 0,
          position: 'absolute',
          color: 'common.white',
        }),
      }}
    >
      <Link to={linkTo} color="inherit" component={RouterLink}>
        <TextMaxLine
          variant={isDesktop && latestPostLarge ? 'h5' : 'subtitle2'}
          line={2}
          persistent
        >
          {post.subject}
        </TextMaxLine>
      </Link>

      <Stack
        flexWrap="wrap"
        direction="row"
        justifyContent="space-between"
        alignContent="center"
        sx={{
          mt: 3,
          color: 'text.disabled',
          ...((latestPostLarge || latestPostSmall) && {
            opacity: 0.64,
            color: 'common.white',
          }),
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{
            color: 'text.disabled',
            ...((latestPostLarge || latestPostSmall) && {
              opacity: 0.64,
              color: 'common.white',
            }),
          }}
        >
          {fDate(post.createdAt)}
        </Typography>
        {handleRate && (
          <TextIconLabelStyle
            key={index}
            onClick={handleRate}
            icon={
              <Iconify
                icon={post.rated ? 'eva:heart-fill' : 'eva:heart-outline'}
                sx={{ width: 16, height: 16, mr: 0.5, color: post.rated ? 'red' : 'grey' }}
              />
            }
            value={fShortenNumber(post.ratedCount)}
            sx={{ typography: 'caption', ml: index === 0 ? 0 : 1.5 }}
          />
        )}
      </Stack>
    </CardContent>
  );
}
