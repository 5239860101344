import {
  UserSendContactFormAdapterApi,
  UserSendContactFormAdapterApiSendContactFormRequest,
} from './generated/common';

export const sendContactForm = async (
  requestParameters: UserSendContactFormAdapterApiSendContactFormRequest
): Promise<void> => {
  const api = new UserSendContactFormAdapterApi();

  await api.sendContactForm(requestParameters);
};
