// @mui
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ScrollLink } from 'react-scroll';
import { sendContactForm } from 'src/api';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import * as Yup from 'yup';
import { useMemo } from 'react';
import { MotionInView, varFade } from '../../components/animate';
import TaCEn from '../../docs/Terms-and-Conditions.pdf';
import TaCSk from '../../docs/Obchodne-Podmienky.pdf';
import { PATH_AUTH } from '../../routes/paths';
import { positions } from '@mui/system';
import i18next from 'i18next';

// components
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(10, 0),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(15, 0),
  },
  backgroundColor: '#151C24',
  backgroundImage: `linear-gradient(69.03deg,
    rgba(21, 28, 36, 0.5) 6%,
    rgba(0, 255, 204, 0.305) 100%)`,
}));
const ScrollLinkStyled = styled(ScrollLink)(({ theme }) => ({
  cursor: 'pointer',
  fontSize: 14,
  '&:hover': {
    textDecoration: 'underline',
  },
}));

const NonLinkStyled = styled('div')(({ theme }) => ({
  fontSize: 14,
}));

// ----------------------------------------------------------------------
type InitialValues = {
  name: string;
  email: string;
  subject: string;
  comment: string;
  agree: boolean;
  afterSubmit?: string;
};

enum LinkTypeEnum {
  SCROLL,
  ROUTER,
  DOC,
  NONE,
}

export default function Contact() {
  const { t, i18n } = useTranslation();

  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();

  const ContactSchema = Yup.object().shape({
    agree: Yup.boolean().isTrue(t('yup.terms')),
    email: Yup.string().email(t('yup.emailMustBeValid')).required(t('yup.emailIsRequired')),
    name: Yup.string().required(t('yup.nameIsRequired')),
    subject: Yup.string().required(t('yup.subjectIsRequired')),
    comment: Yup.string().required(t('yup.commentIsRequired')),
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      name: '',
      email: '',
      subject: '',
      comment: '',
      agree: false,
    },
    validationSchema: ContactSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        // resetPassword?.(values.email);
        await sendContactForm({
          sendContactFormRequest: {
            name: values.name,
            email: values.email,
            subject: values.subject,
            comment: values.comment,
          },
        });
        enqueueSnackbar(t('snackbar.contactFormSended'));
        resetForm();
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.error(error);
        if (isMountedRef.current) {
          setSubmitting(false);
          setErrors({ afterSubmit: error.response.data.message });
        }
      }
    },
  });

  const { errors, values, touched, isSubmitting, handleSubmit, getFieldProps } = formik;

  const LINKS = useMemo(
    () => [
      // TO DO  href: '#'
      {
        headline: t('landingPage.contact.footer.ourCompany'),
        children: [
          { name: t('landingPage.contact.footer.main'), href: 'home', link: LinkTypeEnum.SCROLL },
          {
            name: t('landingPage.contact.footer.aboutUs'),
            href: 'about',
            link: LinkTypeEnum.SCROLL,
          },
          {
            name: t('landingPage.contact.footer.basicInfo'),
            href: 'basic',
            link: LinkTypeEnum.SCROLL,
          },
          {
            name: t('landingPage.contact.footer.benefits'),
            href: 'benefits',
            link: LinkTypeEnum.SCROLL,
          },
        ],
      },
      {
        headline: t('landingPage.contact.footer.documents'),
        children: [
          // { name: t('landingPage.contact.gdpr'), href: '#' },
          {
            name: t('landingPage.contact.footer.termsAndConditions'),
            href: i18n.language === 'en' ? TaCEn : TaCSk,
            link: LinkTypeEnum.DOC,
          },
        ],
      },
      {
        headline: t('landingPage.contact.footer.usefulLinks'),
        children: [
          {
            name: t('landingPage.contact.footer.login'),
            href: PATH_AUTH.login,
            link: LinkTypeEnum.ROUTER,
          },
          {
            name: t('landingPage.contact.footer.register'),
            href: PATH_AUTH.register,
            link: LinkTypeEnum.ROUTER,
          },
          { name: t('landingPage.contact.footer.faq'), href: 'faq', link: LinkTypeEnum.SCROLL },
        ],
      },
      {
        headline: t('landingPage.contact.footer.contact'),
        children: [
          { name: t('landingPage.contact.footer.address'), href: '#', link: LinkTypeEnum.NONE },
        ],
      },
    ],
    [i18n, t]
  );

  const docHref = i18n.language === 'en' ? TaCEn : TaCSk;

  return (
    <RootStyle>
      <Box
        component="img"
        src="/images/contact_illustration.svg"
        position={'absolute'}
        right={'0'}
      />
      <Container>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={{ xs: 8, md: 3 }}
          sx={{ zIndex: 1300 }}
        >
          <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
            <Box
              sx={{
                textAlign: 'left',
                mb: { xs: 10, md: 4 },
              }}
            >
              <MotionInView variants={varFade().inUp}>
                <Typography
                  variant="h2"
                  sx={{
                    color: 'common.white',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {t('landingPage.contact.title')}
                </Typography>
              </MotionInView>

              <MotionInView variants={varFade().inDown}>
                <div className="horizontal-line" />
                <Typography
                  component="div"
                  variant="overline"
                  sx={{ mb: 2, color: 'text.disabled' }}
                />
              </MotionInView>
            </Box>
          </Grid>
        </Grid>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={{ xs: 2, md: 3 }}
              sx={{
                mb: { xs: 2, md: 4 },
              }}
            >
              <Grid item xs={12} md={6} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                <Stack spacing={5}>
                  <Stack spacing={3}>
                    <MotionInView variants={varFade().inUp}>
                      <TextField
                        fullWidth
                        {...getFieldProps('name')}
                        label={t('landingPage.contact.name')}
                        variant="standard"
                        sx={{ input: { color: 'white' } }}
                        error={Boolean(touched.name && errors.name)}
                        helperText={touched.name && errors.name}
                      />
                    </MotionInView>

                    <MotionInView variants={varFade().inUp}>
                      <TextField
                        fullWidth
                        {...getFieldProps('email')}
                        label={t('landingPage.contact.email')}
                        variant="standard"
                        sx={{ input: { color: 'white' } }}
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                      />
                    </MotionInView>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={12} md={6} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                <Stack spacing={5}>
                  <Stack spacing={3}>
                    <MotionInView variants={varFade().inUp}>
                      <TextField
                        fullWidth
                        {...getFieldProps('subject')}
                        label={t('landingPage.contact.subject')}
                        variant="standard"
                        sx={{ input: { color: 'white' } }}
                        error={Boolean(touched.subject && errors.subject)}
                        helperText={touched.subject && errors.subject}
                      />
                    </MotionInView>

                    <MotionInView variants={varFade().inUp}>
                      <TextField
                        fullWidth
                        {...getFieldProps('comment')}
                        label={t('landingPage.contact.comment')}
                        variant="standard"
                        sx={{ input: { color: 'white' } }}
                        error={Boolean(touched.comment && errors.comment)}
                        helperText={touched.comment && errors.comment}
                      />
                    </MotionInView>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={{ xs: 8, md: 3 }}
            >
              <Grid item xs={12} md={4}>
                <FormControlLabel
                  label={
                    <Link
                      href={docHref}
                      target="_blank"
                      color="inherit"
                      variant="body2"
                      sx={{ display: 'block' }}
                    >
                      {t('field.terms')}
                    </Link>
                  }
                  control={<Checkbox {...getFieldProps('agree')} checked={values.agree} />}
                  style={{ color: '#fff' }}
                />
                <Stack>
                  {touched.agree && errors.agree && (
                    <FormHelperText sx={{}} error>
                      {errors.agree}
                    </FormHelperText>
                  )}
                </Stack>
              </Grid>
            </Grid>
            <Grid item xs={12} md={2}>
              <MotionInView variants={varFade().inUp} sx={{ mt: 1 }}>
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  {t('landingPage.contact.send')}
                </LoadingButton>
              </MotionInView>
            </Grid>
          </Form>
        </FormikProvider>

        <div className="divider-contact">
          <Divider />
        </div>

        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' }, color: 'common.white' }}
        >
          <Grid item xs={12} sx={{ mb: 3 }} />

          <Grid item xs={8} md={3}>
            <Typography variant="h4" sx={{ pr: { md: 5 }, color: '#01AB55' }} textAlign={'center'}>
              Artemis group
            </Typography>
            <Box component="img" src="/logo/logo_single.svg" width={'100%'} />
          </Grid>

          <Grid item xs={12} md={8} sx={{ zIndex: 1300 }}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
            >
              {LINKS.map((list) => (
                <Stack key={list.headline} spacing={2}>
                  <Typography component="p" variant="overline" noWrap={true}>
                    {list.headline}
                  </Typography>
                  {list.children.map((link) => {
                    if (link.link === LinkTypeEnum.ROUTER)
                      return (
                        <Link
                          to={link.href}
                          key={link.name}
                          color="inherit"
                          variant="body2"
                          component={RouterLink}
                          sx={{ display: 'block', cursor: 'pointer' }}
                        >
                          {link.name}
                        </Link>
                      );
                    else if (link.link === LinkTypeEnum.SCROLL)
                      return (
                        <ScrollLinkStyled to={link.href} key={link.name} color="inherit">
                          {link.name}
                        </ScrollLinkStyled>
                      );
                    else if (link.link === LinkTypeEnum.DOC)
                      return (
                        <Link
                          href={link.href}
                          key={link.name}
                          target="_blank"
                          color="inherit"
                          variant="body2"
                          sx={{ display: 'block', cursor: 'pointer' }}
                        >
                          {link.name}
                        </Link>
                      );
                    else return <NonLinkStyled key={link.name}> {link.name}</NonLinkStyled>;
                  })}
                </Stack>
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </RootStyle>
  );
}
