import { Box, BoxProps } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  disabledLink?: boolean;
}

export default function Logo({ disabledLink = false, sx }: Props) {
  const logo = (
    <Box component="img" src="/logo/logo_single.svg" sx={{ width:50, height: 50, ...sx }} />
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="/">{logo}</RouterLink>;
}
