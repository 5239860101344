import { format, getTime, formatDistanceToNow, differenceInDays, isValid } from 'date-fns';

// ----------------------------------------------------------------------

export function fDate(date: Date | string | number | undefined) {
  if (!date) return '';
  return format(new Date(date), 'dd MMMM yyyy');
}

export function fDateApi(date: Date | string | number | undefined | null) {
  if (!date) return '';
  return format(new Date(date), 'yyyy-MM-dd');
}

export function fDateTime(date: Date | string | number) {
  return format(new Date(date), 'dd MMM yyyy p');
}

export function fTimestamp(date: Date | string | number) {
  return getTime(new Date(date));
}

export function fDateTimeSuffix(date: Date | string | number) {
  return format(new Date(date), 'dd/MM/yyyy hh:mm p');
}

export function fToNow(date: Date | string | number) {
  return formatDistanceToNow(new Date(date), {
    addSuffix: true,
  });
}

export function fChartDate(date: Date | string | number) {
  return format(new Date(date), 'MM/dd/yyyy');
}

export function fDateDifference(dateFrom: Date | string | number | null, dateTo: Date | string | number | null) {
  if(!dateFrom || !dateTo) return 0
  return differenceInDays(new Date(dateFrom), new Date(dateTo));
}

export function fMonthShortName(month: number): string {
  const date = new Date();
  date.setMonth(month);
  return format(new Date(date), 'MMM');
}

export function fDateISO(date: Date | string | number) {
  if (!date) return '';
  const nDate = new Date(date);
  nDate.setHours(2);
  console.log(nDate);
  return new Date(nDate).toISOString();
}

export function fMonthName(month: number): string {
  const date = new Date();
  date.setMonth(month);
  return format(new Date(date), 'MMMM');
}

export function fValidDate(date: Date | string | number | null) {
  return isValid(date);
}
