import { getAuthConfiguration } from './auth';
import {
  CryptoData,
  CryptoDataResponse,
  ManageVipIndexRestAdapterApi,
  ManageVipIndexRestAdapterApiListByTypeRequest,
  ManageVipIndexRestAdapterApiLoadVipIndexTransactionListRequest,
  PageVipIndexActivityDTO,
  PageVipIndexTransactionDTO,
  VipIndexDistributionResponse,
} from './generated/client';

export const loadVipIndexList = async (): Promise<VipIndexDistributionResponse> => {
  const conf = getAuthConfiguration();
  const api = new ManageVipIndexRestAdapterApi(conf);

  return (await api.loadVipIndexDistribution()).data;
};

export const loadVipIndexTransactionList = async (
  requestParameters: ManageVipIndexRestAdapterApiLoadVipIndexTransactionListRequest
): Promise<PageVipIndexTransactionDTO> => {
  const conf = getAuthConfiguration();
  const api = new ManageVipIndexRestAdapterApi(conf);

  return (await api.loadVipIndexTransactionList(requestParameters)).data.pagedData;
};

export const loadCryptoData = async (): Promise<CryptoDataResponse> => {
  const conf = getAuthConfiguration();
  const api = new ManageVipIndexRestAdapterApi(conf);

  return (await api.loadCryptoData()).data;
};

export const loadVipIndexActivity = async (
  requestParameters: ManageVipIndexRestAdapterApiListByTypeRequest
): Promise<PageVipIndexActivityDTO> => {
  const conf = getAuthConfiguration();
  const api = new ManageVipIndexRestAdapterApi(conf);

  return (await api.listByType(requestParameters)).data.pagedData;
};
