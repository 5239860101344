import { LoadingButton } from '@mui/lab';
// @mui
import { Checkbox, FormControlLabel, Link, Stack, useTheme } from '@mui/material';
import { Form, FormikProvider, useFormik } from 'formik';
import CookieConsent, { Cookies } from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
// components
// hooks
import * as Yup from 'yup';
import TaCEn from '../docs/Terms-and-Conditions.pdf';
import TaCSk from '../docs/Obchodne-Podmienky.pdf';
// ----------------------------------------------------------------------

type InitialValues = {
  requiredCookie: boolean;
  analyticsCookie: boolean;
  marketingCookie: boolean;
  afterSubmit?: string;
};
export default function CustomCookieConsent() {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const CookieSchema = Yup.object().shape({
    username: Yup.string().required(t('loginForm.userNameIsRequired')),
    password: Yup.string().required(t('loginForm.passwordIsRequired')),
  });

  const formik = useFormik<InitialValues>({
    initialValues: {
      requiredCookie: true,
      analyticsCookie: false,
      marketingCookie: false,
    },
    validationSchema: CookieSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        console.log(values);
      } catch (error) {}
    },
  });

  const { values, getFieldProps } = formik;
  const docHref = i18n.language === 'en' ? TaCEn : TaCSk;

  return (
    <CookieConsent
      onAccept={() => {
        Cookies.set('acceptedValue', JSON.stringify(values), { expires: 999 });
      }}
      enableDeclineButton
      style={{ zIndex: 9999, display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}
      contentStyle={{ flex: 1 }}
      ButtonComponent={LoadingButton}
      buttonStyle={{
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: 16,
        borderRadius: 8,
      }}
      declineButtonStyle={{
        background: theme.palette.primary.main,
        color: theme.palette.common.white,
        fontSize: 16,
        borderRadius: 8,
      }}
      buttonText={t('cookie.acceptAll')}
      declineButtonText={t('cookie.savePref')}
      onDecline={() => {
        Cookies.set('acceptedValue', JSON.stringify(values), { expires: 999 });
      }}
    >
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate>
          <span> {t('cookie.cookieText')}</span>{' '}
          <span>
            <Link
              href={docHref}
              target="_blank"
              color="inherit"
              variant="body2"
              sx={{ textDecoration: 'underline' }}
            >
              {t('cookie.moreInfo')}
            </Link>
          </span>
          <Stack direction="column" justifyContent="space-between" sx={{ my: 2 }}>
            <FormControlLabel
              control={
                <Checkbox {...getFieldProps('requiredCookie')} checked={values.requiredCookie} />
              }
              label={<>{t('cookie.requiredCookie')}</>}
            />
            <FormControlLabel
              control={
                <Checkbox {...getFieldProps('analyticsCookie')} checked={values.analyticsCookie} />
              }
              label={<>{t('cookie.analyticsCookie')}</>}
            />
            <FormControlLabel
              control={
                <Checkbox {...getFieldProps('marketingCookie')} checked={values.marketingCookie} />
              }
              label={<>{t('cookie.marketingCookie')}</>}
            />
          </Stack>
        </Form>
      </FormikProvider>
    </CookieConsent>
  );
}
