import { getAuthConfiguration } from './auth';
import {
} from './generated/client';
import { LoadClosedTradeRestAdapterApiList1Request, LoadClosedTradeRestAdapterApi } from './generated/client/api/load-closed-trade-rest-adapter-api';
import { PageClosedTradeDTO } from './generated/client/model/page-closed-trade-dto';

export const getCloseTradeList = async (
  requestParameters: LoadClosedTradeRestAdapterApiList1Request
): Promise<PageClosedTradeDTO> => {
  const conf = getAuthConfiguration();
  const api = new LoadClosedTradeRestAdapterApi(conf);

  return (await api.list1(requestParameters)).data.pagedData;
};
