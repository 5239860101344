import { createContext, ReactNode, useEffect, useReducer } from 'react';
import {
  LoginRequestRoleNameEnum,
  RegistrationRestAdapterApiRegisterRequest,
  ResetPasswordRequest,
  ValidateCodeRequestRoleNameEnum,
} from 'src/api/generated/common';
// @types
import { ActionMap, AuthState, AuthUser, JWTContextType } from '../@types/auth';
import {
  forgotPassword,
  getUser,
  loginUser,
  changeUserPassword,
  sendInvitation,
  registerUser,
} from 'src/api/auth';
import { isValidToken, setSession } from '../utils/jwt';
import { redirectUrl } from 'src/config';
import { validateCode } from 'src/api';

// ----------------------------------------------------------------------

enum Types {
  Initial = 'INITIALIZE',
  Login = 'LOGIN',
  Security = 'SECURITY',
  Logout = 'LOGOUT',
  Register = 'REGISTER',
}

type JWTAuthPayload = {
  [Types.Initial]: {
    isAuthenticated: boolean;
    user: AuthUser;
  };
  [Types.Security]: {
    need2fa: boolean;
  };
  [Types.Login]: {
    user: AuthUser;
  };
  [Types.Logout]: undefined;
  [Types.Register]: {
    user: AuthUser;
  };
};

export type JWTActions = ActionMap<JWTAuthPayload>[keyof ActionMap<JWTAuthPayload>];

const initialState: AuthState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  need2fa: false,
};

const JWTReducer = (state: AuthState, action: JWTActions) => {
  switch (action.type) {
    case 'INITIALIZE':
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
        need2fa: false,
      };
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        need2fa: false,
      };
    case 'SECURITY':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        need2fa: true,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        need2fa: false,
      };

    default:
      return state;
  }
};

const AuthContext = createContext<JWTContextType | null>(null);

function AuthProvider({ children }: { children: ReactNode }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);

  const loadUser = async () => {
    try {
      const user = await getUser();
      dispatch({
        type: Types.Initial,
        payload: {
          isAuthenticated: true,
          user,
        },
      });
    } catch {}
  };

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          setTimeout(async () => {
            const user = await getUser();
            dispatch({
              type: Types.Initial,
              payload: {
                isAuthenticated: true,
                user,
              },
            });
          }, 400);
        } else {
          dispatch({
            type: Types.Initial,
            payload: {
              isAuthenticated: false,
              user: null,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Initial,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (username: string, password: string, code?: string): Promise<boolean> => {
    if (!code) {
      const response = await loginUser({
        username,
        password,
        roleName: LoginRequestRoleNameEnum.Client,
      });

      if (response.isSecurityEnabled) {
        dispatch({
          type: Types.Security,
          payload: {
            need2fa: true,
          },
        });
        if (response.token) setSession(response.token);
        return false;
      } else {
        if (response.token) setSession(response.token);

        const user = await getUser();

        dispatch({
          type: Types.Login,
          payload: {
            user,
          },
        });
        return false;
      }
    } else {
      const response = await validateCode({
        validateCodeRequest: {
          username,
          password,
          roleName: ValidateCodeRequestRoleNameEnum.Client,
          code,
        },
      });
      if (response.token) setSession(response.token);

      const user = await getUser();

      dispatch({
        type: Types.Login,
        payload: {
          user,
        },
      });

      return true;
    }
  };

  const register = async (registerParameters: RegistrationRestAdapterApiRegisterRequest) =>
    registerUser(registerParameters);

  const logout = async () => {
    setSession(null);
    dispatch({ type: Types.Logout });
  };

  const setToken = async (accessToken: string) => {
    setSession(accessToken);
  };

  const resetPassword = (email: string) => {
    forgotPassword(email);
  };

  const changePassword = (resetPasswordRequest: ResetPasswordRequest) =>
    changeUserPassword(resetPasswordRequest);

  const inviteUser = (email: string): Promise<object> =>
    sendInvitation({ email, redirectUrl: redirectUrl.clientBaseUrl });

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
        inviteUser,
        resetPassword,
        changePassword,
        loadUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
