/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface InboxMessageDTO
 */
export interface InboxMessageDTO {
    /**
     * 
     * @type {number}
     * @memberof InboxMessageDTO
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof InboxMessageDTO
     */
    createdAt: string;
    /**
     * 
     * @type {string}
     * @memberof InboxMessageDTO
     */
    updatedAt?: string;
    /**
     * 
     * @type {string}
     * @memberof InboxMessageDTO
     */
    content: string;
    /**
     * 
     * @type {string}
     * @memberof InboxMessageDTO
     */
    previewImage: string;
    /**
     * 
     * @type {string}
     * @memberof InboxMessageDTO
     */
    subject: string;
    /**
     * 
     * @type {string}
     * @memberof InboxMessageDTO
     */
    description: string;
    /**
     * 
     * @type {string}
     * @memberof InboxMessageDTO
     */
    inboxMessageType: InboxMessageDTOInboxMessageTypeEnum;
    /**
     * 
     * @type {boolean}
     * @memberof InboxMessageDTO
     */
    isRead: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InboxMessageDTO
     */
    rated: boolean;
    /**
     * 
     * @type {number}
     * @memberof InboxMessageDTO
     */
    ratedCount: number;
}

/**
    * @export
    * @enum {string}
    */
export enum InboxMessageDTOInboxMessageTypeEnum {
    Marketing = 'MARKETING',
    News = 'NEWS',
    Information = 'INFORMATION',
    Webinar = 'WEBINAR'
}



