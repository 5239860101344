// @mui
import { alpha, Box, Button, Card, Container, Grid, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Image from 'src/components/Image';
import { MotionInView, varFade } from '../../components/animate';
import { useEffect, useState } from 'react';
import { loadLastNews } from 'src/api';
import { InboxMessageDTO } from 'src/api/generated/common';
import { NewsPostContent } from '../@dashboard/news/NewsPostCard';
import { PATH_AUTH } from 'src/routes/paths';

// _mock_
// components
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(10, 0),
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(15, 0),
  },
}));

const OverlayStyle = styled('div')(({ theme }) => ({
  top: 0,
  zIndex: 1,
  width: '100%',
  height: '100%',
  position: 'absolute',
  backgroundColor: alpha(theme.palette.grey[900], 0.8),
}));

// ----------------------------------------------------------------------

export default function Articles() {
  const { t } = useTranslation();
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';

  const [newsList, setNewsList] = useState<InboxMessageDTO[]>([]);

  useEffect(() => {
    const loadList = async () => {
      try {
        const response = await loadLastNews();
        setNewsList(response);
      } catch {}
    };

    loadList();
  }, []);

  return (
    <RootStyle>
      <Container>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={{ xs: 8, md: 3 }}
        >
          <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
            <Box
              sx={{
                textAlign: 'left',
                mb: { xs: 10, md: 5 },
              }}
            >
              <MotionInView variants={varFade().inUp}>
                <Typography
                  variant="h2"
                  sx={{
                    color: 'common.black',
                  }}
                >
                  {t('landingPage.ourPartners.title')}
                </Typography>
              </MotionInView>

              <MotionInView variants={varFade().inDown}>
                <div className="horizontal-line" />
                <Typography
                  component="div"
                  variant="overline"
                  sx={{ mb: 2, color: 'text.disabled' }}
                />
              </MotionInView>
            </Box>
          </Grid>
        </Grid>

        <Grid item xs={12} md={12} dir="ltr">
          <Box
            sx={{
              textAlign: 'center',
              mb: { xs: 10, md: 25 },
            }}
          >
            <MotionInView variants={varFade().inUp}>
              <div style={{ display: 'inline-flex' }}>
                <a href="https://nuzai.finance/" target="_blank">
                  <Box component="img" src="/images/nuzai_logo.svg" />
                </a>
              </div>
            </MotionInView>

            <MotionInView variants={varFade().inDown}>
              <Typography
                sx={{
                  mt: 5,
                  color: isLight ? 'text.secondary' : 'common.white',
                }}
              >
                {t('landingPage.ourPartners.nuzai')}
                <br />
                {t('landingPage.ourPartners.description')}
              </Typography>
            </MotionInView>
          </Box>
        </Grid>

        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={{ xs: 8, md: 3 }}
        >
          <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
            <Box
              sx={{
                textAlign: 'left',
                mb: { xs: 5, md: 5 },
              }}
            >
              <MotionInView variants={varFade().inUp}>
                <Typography
                  variant="h2"
                  sx={{
                    color: 'common.black',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {t('landingPage.latestArticles.title')}
                </Typography>
              </MotionInView>

              <MotionInView variants={varFade().inDown}>
                <div className="horizontal-line" />
                <Typography
                  component="div"
                  variant="overline"
                  sx={{ mb: 2, color: 'text.disabled' }}
                />
              </MotionInView>
            </Box>
          </Grid>
        </Grid>

        <Grid container spacing={5} justifyContent="center">
          {newsList.map((item, index) => (
            <Grid key={item.id} item xs={12} md={4} dir="ltr">
              {/* <Card>
                <Box sx={{ position: 'relative' }}>
                  <Image
                    alt="cover"
                    src={`data:image/image/png;base64,${item.previewImage}`}
                    ratio="4/3"
                  />
                </Box>

                <NewsPostContent post={item} handleRate={() => undefined} />
              </Card> */}
              <Card>
                <NewsPostContent post={item} index={index} link={PATH_AUTH.register} />

                <OverlayStyle />

                <Image
                  alt="cover"
                  src={`data:image/image/png;base64,${item.previewImage}`}
                  sx={{ height: 360 }}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </RootStyle>
  );
}

// ----------------------------------------------------------------------
