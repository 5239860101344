import './index.css';

import { Box, Button, Container, Stack, StackProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { m } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { MotionContainer, varFade } from '../../components/animate';
import { PATH_AUTH, PATH_DASHBOARD } from '../../routes/paths';
import useAuth from 'src/hooks/useAuth';

// @mui
// routes
// components
// ----------------------------------------------------------------------

const RootStyle = styled(m.div)(({ theme }) => ({
  position: 'relative',
  backgroundColor: '#fff',
  [theme.breakpoints.up('md')]: {
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    display: 'flex',
    position: 'fixed',
    alignItems: 'center',
  },
}));

const ContentStyle = styled((props: StackProps) => <Stack spacing={5} {...props} />)(
  ({ theme }) => ({
    zIndex: 10,
    maxWidth: 520,
    margin: 'auto',
    textAlign: 'center',
    position: 'relative',
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.up('md')]: {
      margin: 'unset',
      textAlign: 'left',
    },
  })
);

// ----------------------------------------------------------------------

export default function Home() {
  const { t } = useTranslation();
  const { user } = useAuth();
  return (
    <MotionContainer>
      <RootStyle>
        <div className="home-wrapper">
          <div className="home-second-wrapper" />
          <Container>
            <ContentStyle>
              <m.div variants={varFade().inRight}>
                <Typography variant="h2" sx={{ color: 'common.black' }}>
                  <Typography component="span" variant="h1" sx={{ color: 'primary.main' }}>
                    &nbsp;{t('landingPage.home.title')}
                    <br />
                  </Typography>
                  {t('landingPage.home.subtitle')}
                </Typography>
              </m.div>

              <m.div variants={varFade().inRight}>
                <Typography sx={{ color: 'common.black' }}>
                  {t('landingPage.home.descriptionFirst')}
                  <br />
                  {t('landingPage.home.descriptionSecond')}
                </Typography>
              </m.div>

              {!user ? (
                <m.div variants={varFade().inRight}>
                  <Button
                    size="large"
                    variant="contained"
                    component={RouterLink}
                    to={PATH_DASHBOARD.root}
                    sx={{
                      width: { xs: 'auto', sm: 'auto', md: 200 },
                      height: { xs: 'auto', sm: 'auto', md: 'auto' },
                      mr: 3,
                    }}
                  >
                    {t('landingPage.home.login')}
                  </Button>

                  <Button
                    size="large"
                    variant="contained"
                    component={RouterLink}
                    to={PATH_AUTH.register}
                    sx={{
                      width: { xs: 'auto', sm: 'auto', md: 200 },
                      height: { xs: 'auto', sm: 'auto', md: 'auto' },
                      mr: 3,
                    }}
                    style={{
                      backgroundColor: "#C9FACD",
                      color: "#01AB55"
                    }}
                  >
                    {t('common.register')}
                  </Button>
                </m.div>
              ) : (
                <m.div variants={varFade().inRight}>
                  <Button
                    size="large"
                    variant="contained"
                    component={RouterLink}
                    to={PATH_DASHBOARD.root}
                    sx={{
                      width: { xs: 'auto', sm: 'auto', md: 200 },
                      height: { xs: 'auto', sm: 'auto', md: 'auto' },
                      mr: 3,
                    }}
                  >
                    {t('nav.dashboard')}
                  </Button>
                </m.div>
              )}
            </ContentStyle>
          </Container>
        </div>
      </RootStyle>
      <Box sx={{ height: { md: '100vh' } }} />
    </MotionContainer>
  );
}
