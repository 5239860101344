/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AbstractPageableResponsePaymentDTO, PaymentHistoryDataPoint } from '../model';
// @ts-ignore
import { AbstractPageableResponsePayoutDTO } from '../model';
// @ts-ignore
import { AbstractPageableResponseShortIndexDTO } from '../model';
// @ts-ignore
import { CurrentBalanceHistoryDataPoint } from '../model';
// @ts-ignore
import { IndexDistributionResponse } from '../model';
// @ts-ignore
import { TotalBalanceStatusResponse } from '../model';
// @ts-ignore
import { TotalStatisticPerMonthResponse } from '../model';
// @ts-ignore
import { UserDTO } from '../model';
/**
 * LoadClientDashboardRestAdapterApi - axios parameter creator
 * @export
 */
export const LoadClientDashboardRestAdapterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadAnnualInvested: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/client-dashboard/load-annual-invested`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBalanceHistory: async (from?: string, to?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/client-dashboard/load-balance-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (from !== undefined) {
                localVarQueryParameter['from'] = (from as any instanceof Date) ?
                    (from as any).toISOString().substr(0,10) :
                    from;
            }

            if (to !== undefined) {
                localVarQueryParameter['to'] = (to as any instanceof Date) ?
                    (to as any).toISOString().substr(0,10) :
                    to;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBalanceHistoryByTrades: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/client-dashboard/load-balance-history-by-trades`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBonusIncome: async (year: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'year' is not null or undefined
            assertParamExists('loadBonusIncome', 'year', year)
            const localVarPath = `/client-dashboard/load-bonus-income`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadCurrentBalance: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/client-dashboard/load-current-balance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {'ESTABLISHED' | 'STARTUP' | 'ACTIVE'} positionType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadHoldIndexDistributionByPositionType: async (positionType: 'ESTABLISHED' | 'STARTUP' | 'ACTIVE', options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'positionType' is not null or undefined
            assertParamExists('loadHoldIndexDistributionByPositionType', 'positionType', positionType)
            const localVarPath = `/client-dashboard/load-hold-index-distribution/{positionType}`
                .replace(`{${"positionType"}}`, encodeURIComponent(String(positionType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadPayments: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/client-dashboard/load-payments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {'APPROVED' | 'PENDING' | 'DECLINED'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadPayoutsByClientIdAndStatus: async (page?: number, size?: number, sort?: Array<string>, status?: 'APPROVED' | 'PENDING' | 'DECLINED', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/client-dashboard/load-payouts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadProfile: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/client-dashboard/load-profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadShortIndexDistribution: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/client-dashboard/load-short-index-distribution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadShortIndices: async (page?: number, size?: number, sort?: Array<string>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/client-dashboard/load-short-indices`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadTotalInvested: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/client-dashboard/load-total-balance-status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadTotalReferrals: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/client-dashboard/load-total-referrals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadTotalWithdrawn: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/client-dashboard/load-total-withdrawn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoadClientDashboardRestAdapterApi - functional programming interface
 * @export
 */
export const LoadClientDashboardRestAdapterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoadClientDashboardRestAdapterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadAnnualInvested(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadAnnualInvested(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadBalanceHistory(from?: string, to?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: CurrentBalanceHistoryDataPoint; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadBalanceHistory(from, to, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadBalanceHistoryByTrades(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: PaymentHistoryDataPoint; }; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadBalanceHistoryByTrades(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadBonusIncome(year: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalStatisticPerMonthResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadBonusIncome(year, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadCurrentBalance(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CurrentBalanceHistoryDataPoint>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadCurrentBalance(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {'ESTABLISHED' | 'STARTUP' | 'ACTIVE'} positionType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadHoldIndexDistributionByPositionType(positionType: 'ESTABLISHED' | 'STARTUP' | 'ACTIVE', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndexDistributionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadHoldIndexDistributionByPositionType(positionType, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadPayments(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractPageableResponsePaymentDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadPayments(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {'APPROVED' | 'PENDING' | 'DECLINED'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadPayoutsByClientIdAndStatus(page?: number, size?: number, sort?: Array<string>, status?: 'APPROVED' | 'PENDING' | 'DECLINED', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractPageableResponsePayoutDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadPayoutsByClientIdAndStatus(page, size, sort, status, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadProfile(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadProfile(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadShortIndexDistribution(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IndexDistributionResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadShortIndexDistribution(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadShortIndices(page?: number, size?: number, sort?: Array<string>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractPageableResponseShortIndexDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadShortIndices(page, size, sort, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadTotalInvested(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalBalanceStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadTotalInvested(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadTotalReferrals(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadTotalReferrals(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadTotalWithdrawn(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadTotalWithdrawn(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoadClientDashboardRestAdapterApi - factory interface
 * @export
 */
export const LoadClientDashboardRestAdapterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoadClientDashboardRestAdapterApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadAnnualInvested(options?: any): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.loadAnnualInvested(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [from] 
         * @param {string} [to] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBalanceHistory(from?: string, to?: string, options?: any): AxiosPromise<{ [key: string]: CurrentBalanceHistoryDataPoint; }> {
            return localVarFp.loadBalanceHistory(from, to, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBalanceHistoryByTrades(options?: any): AxiosPromise<{ [key: string]: { [key: string]: PaymentHistoryDataPoint; }; }> {
            return localVarFp.loadBalanceHistoryByTrades(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} year 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadBonusIncome(year: number, options?: any): AxiosPromise<TotalStatisticPerMonthResponse> {
            return localVarFp.loadBonusIncome(year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadCurrentBalance(options?: any): AxiosPromise<CurrentBalanceHistoryDataPoint> {
            return localVarFp.loadCurrentBalance(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {'ESTABLISHED' | 'STARTUP' | 'ACTIVE'} positionType 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadHoldIndexDistributionByPositionType(positionType: 'ESTABLISHED' | 'STARTUP' | 'ACTIVE', options?: any): AxiosPromise<IndexDistributionResponse> {
            return localVarFp.loadHoldIndexDistributionByPositionType(positionType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadPayments(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<AbstractPageableResponsePaymentDTO> {
            return localVarFp.loadPayments(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {'APPROVED' | 'PENDING' | 'DECLINED'} [status] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadPayoutsByClientIdAndStatus(page?: number, size?: number, sort?: Array<string>, status?: 'APPROVED' | 'PENDING' | 'DECLINED', options?: any): AxiosPromise<AbstractPageableResponsePayoutDTO> {
            return localVarFp.loadPayoutsByClientIdAndStatus(page, size, sort, status, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadProfile(options?: any): AxiosPromise<UserDTO> {
            return localVarFp.loadProfile(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadShortIndexDistribution(options?: any): AxiosPromise<IndexDistributionResponse> {
            return localVarFp.loadShortIndexDistribution(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadShortIndices(page?: number, size?: number, sort?: Array<string>, options?: any): AxiosPromise<AbstractPageableResponseShortIndexDTO> {
            return localVarFp.loadShortIndices(page, size, sort, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadTotalInvested(options?: any): AxiosPromise<TotalBalanceStatusResponse> {
            return localVarFp.loadTotalInvested(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadTotalReferrals(options?: any): AxiosPromise<number> {
            return localVarFp.loadTotalReferrals(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadTotalWithdrawn(options?: any): AxiosPromise<number> {
            return localVarFp.loadTotalWithdrawn(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for loadBalanceHistory operation in LoadClientDashboardRestAdapterApi.
 * @export
 * @interface LoadClientDashboardRestAdapterApiLoadBalanceHistoryRequest
 */
export interface LoadClientDashboardRestAdapterApiLoadBalanceHistoryRequest {
    /**
     * 
     * @type {string}
     * @memberof LoadClientDashboardRestAdapterApiLoadBalanceHistory
     */
    readonly from?: string

    /**
     * 
     * @type {string}
     * @memberof LoadClientDashboardRestAdapterApiLoadBalanceHistory
     */
    readonly to?: string
}

/**
 * Request parameters for loadBonusIncome operation in LoadClientDashboardRestAdapterApi.
 * @export
 * @interface LoadClientDashboardRestAdapterApiLoadBonusIncomeRequest
 */
export interface LoadClientDashboardRestAdapterApiLoadBonusIncomeRequest {
    /**
     * 
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadBonusIncome
     */
    readonly year: number
}

/**
 * Request parameters for loadHoldIndexDistributionByPositionType operation in LoadClientDashboardRestAdapterApi.
 * @export
 * @interface LoadClientDashboardRestAdapterApiLoadHoldIndexDistributionByPositionTypeRequest
 */
export interface LoadClientDashboardRestAdapterApiLoadHoldIndexDistributionByPositionTypeRequest {
    /**
     * 
     * @type {'ESTABLISHED' | 'STARTUP' | 'ACTIVE'}
     * @memberof LoadClientDashboardRestAdapterApiLoadHoldIndexDistributionByPositionType
     */
    readonly positionType: 'ESTABLISHED' | 'STARTUP' | 'ACTIVE'
}

/**
 * Request parameters for loadPayments operation in LoadClientDashboardRestAdapterApi.
 * @export
 * @interface LoadClientDashboardRestAdapterApiLoadPaymentsRequest
 */
export interface LoadClientDashboardRestAdapterApiLoadPaymentsRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadPayments
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadPayments
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoadClientDashboardRestAdapterApiLoadPayments
     */
    readonly sort?: Array<string>
}

/**
 * Request parameters for loadPayoutsByClientIdAndStatus operation in LoadClientDashboardRestAdapterApi.
 * @export
 * @interface LoadClientDashboardRestAdapterApiLoadPayoutsByClientIdAndStatusRequest
 */
export interface LoadClientDashboardRestAdapterApiLoadPayoutsByClientIdAndStatusRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadPayoutsByClientIdAndStatus
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadPayoutsByClientIdAndStatus
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoadClientDashboardRestAdapterApiLoadPayoutsByClientIdAndStatus
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {'APPROVED' | 'PENDING' | 'DECLINED'}
     * @memberof LoadClientDashboardRestAdapterApiLoadPayoutsByClientIdAndStatus
     */
    readonly status?: 'APPROVED' | 'PENDING' | 'DECLINED'
}

/**
 * Request parameters for loadShortIndices operation in LoadClientDashboardRestAdapterApi.
 * @export
 * @interface LoadClientDashboardRestAdapterApiLoadShortIndicesRequest
 */
export interface LoadClientDashboardRestAdapterApiLoadShortIndicesRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadShortIndices
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoadClientDashboardRestAdapterApiLoadShortIndices
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoadClientDashboardRestAdapterApiLoadShortIndices
     */
    readonly sort?: Array<string>
}

/**
 * LoadClientDashboardRestAdapterApi - object-oriented interface
 * @export
 * @class LoadClientDashboardRestAdapterApi
 * @extends {BaseAPI}
 */
export class LoadClientDashboardRestAdapterApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadAnnualInvested(options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadAnnualInvested(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadClientDashboardRestAdapterApiLoadBalanceHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadBalanceHistory(requestParameters: LoadClientDashboardRestAdapterApiLoadBalanceHistoryRequest = {}, options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadBalanceHistory(requestParameters.from, requestParameters.to, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadBalanceHistoryByTrades(options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadBalanceHistoryByTrades(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadClientDashboardRestAdapterApiLoadBonusIncomeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadBonusIncome(requestParameters: LoadClientDashboardRestAdapterApiLoadBonusIncomeRequest, options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadBonusIncome(requestParameters.year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadCurrentBalance(options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadCurrentBalance(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadClientDashboardRestAdapterApiLoadHoldIndexDistributionByPositionTypeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadHoldIndexDistributionByPositionType(requestParameters: LoadClientDashboardRestAdapterApiLoadHoldIndexDistributionByPositionTypeRequest, options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadHoldIndexDistributionByPositionType(requestParameters.positionType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadClientDashboardRestAdapterApiLoadPaymentsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadPayments(requestParameters: LoadClientDashboardRestAdapterApiLoadPaymentsRequest = {}, options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadPayments(requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadClientDashboardRestAdapterApiLoadPayoutsByClientIdAndStatusRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadPayoutsByClientIdAndStatus(requestParameters: LoadClientDashboardRestAdapterApiLoadPayoutsByClientIdAndStatusRequest = {}, options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadPayoutsByClientIdAndStatus(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.status, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadProfile(options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadProfile(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadShortIndexDistribution(options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadShortIndexDistribution(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadClientDashboardRestAdapterApiLoadShortIndicesRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadShortIndices(requestParameters: LoadClientDashboardRestAdapterApiLoadShortIndicesRequest = {}, options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadShortIndices(requestParameters.page, requestParameters.size, requestParameters.sort, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadTotalInvested(options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadTotalInvested(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadTotalReferrals(options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadTotalReferrals(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClientDashboardRestAdapterApi
     */
    public loadTotalWithdrawn(options?: any) {
        return LoadClientDashboardRestAdapterApiFp(this.configuration).loadTotalWithdrawn(options).then((request) => request(this.axios, this.basePath));
    }
}
