import { getAuthConfiguration } from './auth';
import {
  Security2FaRestAdapterApi,
  Security2FaRestAdapterApiActivateRequest,
  Security2FaRestAdapterApiValidateCodeRequest,
  TwoFANumberPair,
  ValidationResponse,
} from './generated/common';

export const generateCode = async (): Promise<string> => {
  const conf = getAuthConfiguration();
  const api = new Security2FaRestAdapterApi(conf);

  return (await api.generateGrCode()).data;
};

export const generateKey = async (): Promise<TwoFANumberPair> => {
  const conf = getAuthConfiguration();
  const api = new Security2FaRestAdapterApi(conf);

  return (await api.generateKeyCode()).data;
};

export const disable2Fa = async (): Promise<void> => {
  const conf = getAuthConfiguration();
  const api = new Security2FaRestAdapterApi(conf);

  await api.disable2fa();
};

export const validateCode = async (
  requestParameters: Security2FaRestAdapterApiValidateCodeRequest
): Promise<ValidationResponse> => {
  const conf = getAuthConfiguration();
  const api = new Security2FaRestAdapterApi(conf);

  return (await api.validateCode(requestParameters)).data;
};

export const activate2Fa = async (
  requestParameters: Security2FaRestAdapterApiActivateRequest
): Promise<ValidationResponse> => {
  const conf = getAuthConfiguration();
  const api = new Security2FaRestAdapterApi(conf);

  return (await api.activate(requestParameters)).data;
};
