/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface BonusIncomeRecord
 */
export interface BonusIncomeRecord {
    /**
     * 
     * @type {string}
     * @memberof BonusIncomeRecord
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof BonusIncomeRecord
     */
    amount: number;
    /**
     * 
     * @type {string}
     * @memberof BonusIncomeRecord
     */
    date: string;
    /**
     * 
     * @type {string}
     * @memberof BonusIncomeRecord
     */
    type: BonusIncomeRecordTypeEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum BonusIncomeRecordTypeEnum {
    First = 'FIRST',
    Second = 'SECOND',
    Third = 'THIRD',
    CompanyFirst = 'COMPANY_FIRST',
    CompanySecond = 'COMPANY_SECOND',
    CompanyThird = 'COMPANY_THIRD',
    SponsorBonus = 'SPONSOR_BONUS',
    AdminBonus = 'ADMIN_BONUS',
    SponsorOutcomeBonus = 'SPONSOR_OUTCOME_BONUS',
    AdminOutcomeBonus = 'ADMIN_OUTCOME_BONUS',
    SponsorVipOutcomeBonus = 'SPONSOR_VIP_OUTCOME_BONUS',
    AdminVipOutcomeBonus = 'ADMIN_VIP_OUTCOME_BONUS',
    Keep = 'KEEP',
    ReserveFund = 'RESERVE_FUND'
}



