/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface ClientPersonalDataUpdateResponse
 */
export interface ClientPersonalDataUpdateResponse {
    /**
     * 
     * @type {string}
     * @memberof ClientPersonalDataUpdateResponse
     */
    cryptoWallet?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientPersonalDataUpdateResponse
     */
    personalId?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientPersonalDataUpdateResponse
     */
    proofOfAddressId?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientPersonalDataUpdateResponse
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ClientPersonalDataUpdateResponse
     */
    surname: string;
    /**
     * 
     * @type {string}
     * @memberof ClientPersonalDataUpdateResponse
     */
    phone: string;
    /**
     * 
     * @type {string}
     * @memberof ClientPersonalDataUpdateResponse
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ClientPersonalDataUpdateResponse
     */
    cryptoWalletAddress?: string;
    /**
     * 
     * @type {string}
     * @memberof ClientPersonalDataUpdateResponse
     */
    status: ClientPersonalDataUpdateResponseStatusEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum ClientPersonalDataUpdateResponseStatusEnum {
    Approved = 'APPROVED',
    Pending = 'PENDING',
    Declined = 'DECLINED'
}



