/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ClientAvailableFundsResponse } from '../model';
// @ts-ignore
import { CoinbaseChargeResource } from '../model';
// @ts-ignore
import { CreateOrderResponse } from '../model';
// @ts-ignore
import { FeesIncomeResponse } from '../model';
// @ts-ignore
import { FeesOutcomeResponse } from '../model';
// @ts-ignore
import { PaymentCreateRequest } from '../model';
// @ts-ignore
import { PayoutCreateRequest } from '../model';
/**
 * ManageClientDashboardRestAdapterApi - axios parameter creator
 * @export
 */
export const ManageClientDashboardRestAdapterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {PaymentCreateRequest} paymentCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coinGatePayment: async (paymentCreateRequest: PaymentCreateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentCreateRequest' is not null or undefined
            assertParamExists('coinGatePayment', 'paymentCreateRequest', paymentCreateRequest)
            const localVarPath = `/client-dashboard/coin-gate-payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PaymentCreateRequest} paymentCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayment: async (paymentCreateRequest: PaymentCreateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'paymentCreateRequest' is not null or undefined
            assertParamExists('createPayment', 'paymentCreateRequest', paymentCreateRequest)
            const localVarPath = `/client-dashboard/create-payment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(paymentCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PayoutCreateRequest} payoutCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayout: async (payoutCreateRequest: PayoutCreateRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'payoutCreateRequest' is not null or undefined
            assertParamExists('createPayout', 'payoutCreateRequest', payoutCreateRequest)
            const localVarPath = `/client-dashboard/create-payout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(payoutCreateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadAvailableFunds: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/client-dashboard/load-available-funds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadIncomeFees: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/client-dashboard/load-income-fees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadOutcomeFees: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/client-dashboard/load-outcome-fees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManageClientDashboardRestAdapterApi - functional programming interface
 * @export
 */
export const ManageClientDashboardRestAdapterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManageClientDashboardRestAdapterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {PaymentCreateRequest} paymentCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async coinGatePayment(paymentCreateRequest: PaymentCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CreateOrderResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.coinGatePayment(paymentCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PaymentCreateRequest} paymentCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPayment(paymentCreateRequest: PaymentCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CoinbaseChargeResource>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPayment(paymentCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PayoutCreateRequest} payoutCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createPayout(payoutCreateRequest: PayoutCreateRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createPayout(payoutCreateRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadAvailableFunds(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ClientAvailableFundsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadAvailableFunds(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadIncomeFees(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeesIncomeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadIncomeFees(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loadOutcomeFees(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FeesOutcomeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loadOutcomeFees(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManageClientDashboardRestAdapterApi - factory interface
 * @export
 */
export const ManageClientDashboardRestAdapterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManageClientDashboardRestAdapterApiFp(configuration)
    return {
        /**
         * 
         * @param {PaymentCreateRequest} paymentCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        coinGatePayment(paymentCreateRequest: PaymentCreateRequest, options?: any): AxiosPromise<CreateOrderResponse> {
            return localVarFp.coinGatePayment(paymentCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PaymentCreateRequest} paymentCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayment(paymentCreateRequest: PaymentCreateRequest, options?: any): AxiosPromise<CoinbaseChargeResource> {
            return localVarFp.createPayment(paymentCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PayoutCreateRequest} payoutCreateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createPayout(payoutCreateRequest: PayoutCreateRequest, options?: any): AxiosPromise<object> {
            return localVarFp.createPayout(payoutCreateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadAvailableFunds(options?: any): AxiosPromise<ClientAvailableFundsResponse> {
            return localVarFp.loadAvailableFunds(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadIncomeFees(options?: any): AxiosPromise<FeesIncomeResponse> {
            return localVarFp.loadIncomeFees(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loadOutcomeFees(options?: any): AxiosPromise<FeesOutcomeResponse> {
            return localVarFp.loadOutcomeFees(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for coinGatePayment operation in ManageClientDashboardRestAdapterApi.
 * @export
 * @interface ManageClientDashboardRestAdapterApiCoinGatePaymentRequest
 */
export interface ManageClientDashboardRestAdapterApiCoinGatePaymentRequest {
    /**
     * 
     * @type {PaymentCreateRequest}
     * @memberof ManageClientDashboardRestAdapterApiCoinGatePayment
     */
    readonly paymentCreateRequest: PaymentCreateRequest
}

/**
 * Request parameters for createPayment operation in ManageClientDashboardRestAdapterApi.
 * @export
 * @interface ManageClientDashboardRestAdapterApiCreatePaymentRequest
 */
export interface ManageClientDashboardRestAdapterApiCreatePaymentRequest {
    /**
     * 
     * @type {PaymentCreateRequest}
     * @memberof ManageClientDashboardRestAdapterApiCreatePayment
     */
    readonly paymentCreateRequest: PaymentCreateRequest
}

/**
 * Request parameters for createPayout operation in ManageClientDashboardRestAdapterApi.
 * @export
 * @interface ManageClientDashboardRestAdapterApiCreatePayoutRequest
 */
export interface ManageClientDashboardRestAdapterApiCreatePayoutRequest {
    /**
     * 
     * @type {PayoutCreateRequest}
     * @memberof ManageClientDashboardRestAdapterApiCreatePayout
     */
    readonly payoutCreateRequest: PayoutCreateRequest
}

/**
 * ManageClientDashboardRestAdapterApi - object-oriented interface
 * @export
 * @class ManageClientDashboardRestAdapterApi
 * @extends {BaseAPI}
 */
export class ManageClientDashboardRestAdapterApi extends BaseAPI {
    /**
     * 
     * @param {ManageClientDashboardRestAdapterApiCoinGatePaymentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManageClientDashboardRestAdapterApi
     */
    public coinGatePayment(requestParameters: ManageClientDashboardRestAdapterApiCoinGatePaymentRequest, options?: any) {
        return ManageClientDashboardRestAdapterApiFp(this.configuration).coinGatePayment(requestParameters.paymentCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManageClientDashboardRestAdapterApiCreatePaymentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManageClientDashboardRestAdapterApi
     */
    public createPayment(requestParameters: ManageClientDashboardRestAdapterApiCreatePaymentRequest, options?: any) {
        return ManageClientDashboardRestAdapterApiFp(this.configuration).createPayment(requestParameters.paymentCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManageClientDashboardRestAdapterApiCreatePayoutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManageClientDashboardRestAdapterApi
     */
    public createPayout(requestParameters: ManageClientDashboardRestAdapterApiCreatePayoutRequest, options?: any) {
        return ManageClientDashboardRestAdapterApiFp(this.configuration).createPayout(requestParameters.payoutCreateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManageClientDashboardRestAdapterApi
     */
    public loadAvailableFunds(options?: any) {
        return ManageClientDashboardRestAdapterApiFp(this.configuration).loadAvailableFunds(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManageClientDashboardRestAdapterApi
     */
    public loadIncomeFees(options?: any) {
        return ManageClientDashboardRestAdapterApiFp(this.configuration).loadIncomeFees(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManageClientDashboardRestAdapterApi
     */
    public loadOutcomeFees(options?: any) {
        return ManageClientDashboardRestAdapterApiFp(this.configuration).loadOutcomeFees(options).then((request) => request(this.axios, this.basePath));
    }
}
