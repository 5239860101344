import { getAuthConfiguration } from './auth';
import {
  ClientAvailableFundsResponse,
  CoinbaseChargeResource,
  CreateOrderResponse,
  FeesIncomeResponse,
  FeesOutcomeResponse,
  ManageClientDashboardRestAdapterApi,
  ManageClientDashboardRestAdapterApiCoinGatePaymentRequest,
  ManageClientDashboardRestAdapterApiCreatePaymentRequest,
  ManageClientDashboardRestAdapterApiCreatePayoutRequest,
} from './generated/client';

export const getAvailableFunds = async (): Promise<ClientAvailableFundsResponse> => {
  const conf = getAuthConfiguration();
  const api = new ManageClientDashboardRestAdapterApi(conf);

  return (await api.loadAvailableFunds()).data;
};

export const createPayment = async (
  requestParameters: ManageClientDashboardRestAdapterApiCreatePaymentRequest
): Promise<CoinbaseChargeResource> => {
  const conf = getAuthConfiguration();
  const api = new ManageClientDashboardRestAdapterApi(conf);

  return (await api.createPayment(requestParameters)).data;
};

export const createPaymentCoinGate = async (
  requestParameters: ManageClientDashboardRestAdapterApiCoinGatePaymentRequest
): Promise<CreateOrderResponse> => {
  const conf = getAuthConfiguration();
  const api = new ManageClientDashboardRestAdapterApi(conf);

  return (await api.coinGatePayment(requestParameters)).data;
};

export const createPayout = async (
  requestParameters: ManageClientDashboardRestAdapterApiCreatePayoutRequest
): Promise<object> => {
  const conf = getAuthConfiguration();
  const api = new ManageClientDashboardRestAdapterApi(conf);

  return (await api.createPayout(requestParameters)).data;
};

export const loadIncomeFees = async (): Promise<FeesIncomeResponse> => {
  const conf = getAuthConfiguration();
  const api = new ManageClientDashboardRestAdapterApi(conf);

  return (await api.loadIncomeFees()).data;
};

export const loadOutcomeFees = async (): Promise<FeesOutcomeResponse> => {
  const conf = getAuthConfiguration();
  const api = new ManageClientDashboardRestAdapterApi(conf);

  return (await api.loadOutcomeFees()).data;
};
