// routes
// components
import { t } from 'i18next';
import { TFunction } from 'react-i18next';
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const ICON_SIZE = {
  width: 22,
  height: 22,
};

const menuConfig = (t: TFunction) => [
  {
    title: t('landingPage.mainHeader.home'),
    icon: <Iconify icon={'eva:home-fill'} {...ICON_SIZE} />,
    path: 'home',
  },
  {
    title: t('landingPage.mainHeader.aboutUs'),
    icon: <Iconify icon={'ic:round-grain'} {...ICON_SIZE} />,
    path: 'about',
  },
  {
    title: t('landingPage.mainHeader.basicInfo'),
    icon: <Iconify icon={'ic:info'} {...ICON_SIZE} />,
    path: 'basic',
  },
  {
    title: t('landingPage.mainHeader.products'),
    icon: <Iconify icon={'ic:twotone-production-quantity-limits'} {...ICON_SIZE} />,
    path: 'products',
  },
  {
    title: t('landingPage.mainHeader.benefits'),
    icon: <Iconify icon={'ic:baseline-add-moderator'} {...ICON_SIZE} />,
    path: 'benefits',
  },
  {
    title: t('landingPage.mainHeader.faq'),
    icon: <Iconify icon={'ic:baseline-back-hand'} {...ICON_SIZE} />,
    path: 'faq',
  },
  {
    title: t('landingPage.mainHeader.lastArticles'),
    icon: <Iconify icon={'ic:baseline-auto-stories'} {...ICON_SIZE} />,
    path: 'articles',
  },
  {
    title: t('landingPage.mainHeader.contact'),
    icon: <Iconify icon={'ic:baseline-contact-phone'} {...ICON_SIZE} />,
    path: 'contact',
  },
];

export default menuConfig;
