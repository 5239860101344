/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */



/**
 * 
 * @export
 * @interface LoginRequest
 */
export interface LoginRequest {
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    username: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    password: string;
    /**
     * 
     * @type {string}
     * @memberof LoginRequest
     */
    roleName: LoginRequestRoleNameEnum;
}

/**
    * @export
    * @enum {string}
    */
export enum LoginRequestRoleNameEnum {
    Admin = 'ADMIN',
    Partner = 'PARTNER',
    Sponsor = 'SPONSOR',
    Client = 'CLIENT',
    Vip = 'VIP'
}



