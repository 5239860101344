import { Box, Card, Stack, styled, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AffiliateNetworkHeadResponse } from 'src/api/generated/common';
import { fCurrency } from 'src/utils/formatNumber';

const StyledText = styled(Typography)({
  textAlign: 'center',
});

type StyledTreeItemProps = {
  data?: AffiliateNetworkHeadResponse;
};

export function AffiliateFirstWidget({ data }: StyledTreeItemProps) {
  const { t } = useTranslation();
  return (
    <Card
      sx={{
        p: 1,
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column',
      }}
    >
      <Box sx={{ flexGrow: 1 }}>
        <Stack direction="row" justifyContent={'space-around'}>
          <StyledText variant="subtitle2">{t('affiliateTree.firstLine')}</StyledText>

          <Stack>
            <StyledText variant="subtitle2">{t('affiliateTree.count')}</StyledText>
            <StyledText variant="h5">{data?.firstLineCount}</StyledText>
          </Stack>
        </Stack>
        <Stack direction="row" justifyContent={'space-around'}>
          <Stack>
            <StyledText variant="subtitle2">{t('affiliateTree.invested')}</StyledText>
            <StyledText variant="h5">{fCurrency(data?.firstLineInvested)}</StyledText>
          </Stack>
          <Stack>
            <StyledText variant="subtitle2">{t('affiliateTree.bonuses')}</StyledText>
            <StyledText variant="h5">{fCurrency(data?.firstLineBonus)}</StyledText>
          </Stack>
        </Stack>
      </Box>
    </Card>
  );
}
