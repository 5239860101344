/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ResetPasswordRequest } from '../model';
/**
 * ResetPasswordRestAdapterApi - axios parameter creator
 * @export
 */
export const ResetPasswordRestAdapterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword: async (resetPasswordRequest: ResetPasswordRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'resetPasswordRequest' is not null or undefined
            assertParamExists('resetPassword', 'resetPasswordRequest', resetPasswordRequest)
            const localVarPath = `/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(resetPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ResetPasswordRestAdapterApi - functional programming interface
 * @export
 */
export const ResetPasswordRestAdapterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ResetPasswordRestAdapterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetPassword(resetPasswordRequest: ResetPasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetPassword(resetPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ResetPasswordRestAdapterApi - factory interface
 * @export
 */
export const ResetPasswordRestAdapterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ResetPasswordRestAdapterApiFp(configuration)
    return {
        /**
         * 
         * @param {ResetPasswordRequest} resetPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resetPassword(resetPasswordRequest: ResetPasswordRequest, options?: any): AxiosPromise<object> {
            return localVarFp.resetPassword(resetPasswordRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for resetPassword operation in ResetPasswordRestAdapterApi.
 * @export
 * @interface ResetPasswordRestAdapterApiResetPasswordRequest
 */
export interface ResetPasswordRestAdapterApiResetPasswordRequest {
    /**
     * 
     * @type {ResetPasswordRequest}
     * @memberof ResetPasswordRestAdapterApiResetPassword
     */
    readonly resetPasswordRequest: ResetPasswordRequest
}

/**
 * ResetPasswordRestAdapterApi - object-oriented interface
 * @export
 * @class ResetPasswordRestAdapterApi
 * @extends {BaseAPI}
 */
export class ResetPasswordRestAdapterApi extends BaseAPI {
    /**
     * 
     * @param {ResetPasswordRestAdapterApiResetPasswordRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ResetPasswordRestAdapterApi
     */
    public resetPassword(requestParameters: ResetPasswordRestAdapterApiResetPasswordRequest, options?: any) {
        return ResetPasswordRestAdapterApiFp(this.configuration).resetPassword(requestParameters.resetPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
