/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AbstractPageableResponseClosedTradeDTO } from '../model';
/**
 * LoadClosedTradeRestAdapterApi - axios parameter creator
 * @export
 */
export const LoadClosedTradeRestAdapterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [localDateFrom] 
         * @param {string} [localDateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list1: async (page?: number, size?: number, sort?: Array<string>, localDateFrom?: string, localDateTo?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/close-trade/list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (localDateFrom !== undefined) {
                localVarQueryParameter['localDateFrom'] = (localDateFrom as any instanceof Date) ?
                    (localDateFrom as any).toISOString().substr(0,10) :
                    localDateFrom;
            }

            if (localDateTo !== undefined) {
                localVarQueryParameter['localDateTo'] = (localDateTo as any instanceof Date) ?
                    (localDateTo as any).toISOString().substr(0,10) :
                    localDateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoadClosedTradeRestAdapterApi - functional programming interface
 * @export
 */
export const LoadClosedTradeRestAdapterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoadClosedTradeRestAdapterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [localDateFrom] 
         * @param {string} [localDateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async list1(page?: number, size?: number, sort?: Array<string>, localDateFrom?: string, localDateTo?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractPageableResponseClosedTradeDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.list1(page, size, sort, localDateFrom, localDateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoadClosedTradeRestAdapterApi - factory interface
 * @export
 */
export const LoadClosedTradeRestAdapterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoadClosedTradeRestAdapterApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [localDateFrom] 
         * @param {string} [localDateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        list1(page?: number, size?: number, sort?: Array<string>, localDateFrom?: string, localDateTo?: string, options?: any): AxiosPromise<AbstractPageableResponseClosedTradeDTO> {
            return localVarFp.list1(page, size, sort, localDateFrom, localDateTo, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for list1 operation in LoadClosedTradeRestAdapterApi.
 * @export
 * @interface LoadClosedTradeRestAdapterApiList1Request
 */
export interface LoadClosedTradeRestAdapterApiList1Request {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof LoadClosedTradeRestAdapterApiList1
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof LoadClosedTradeRestAdapterApiList1
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof LoadClosedTradeRestAdapterApiList1
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof LoadClosedTradeRestAdapterApiList1
     */
    readonly localDateFrom?: string

    /**
     * 
     * @type {string}
     * @memberof LoadClosedTradeRestAdapterApiList1
     */
    readonly localDateTo?: string
}

/**
 * LoadClosedTradeRestAdapterApi - object-oriented interface
 * @export
 * @class LoadClosedTradeRestAdapterApi
 * @extends {BaseAPI}
 */
export class LoadClosedTradeRestAdapterApi extends BaseAPI {
    /**
     * 
     * @param {LoadClosedTradeRestAdapterApiList1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadClosedTradeRestAdapterApi
     */
    public list1(requestParameters: LoadClosedTradeRestAdapterApiList1Request = {}, options?: any) {
        return LoadClosedTradeRestAdapterApiFp(this.configuration).list1(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.localDateFrom, requestParameters.localDateTo, options).then((request) => request(this.axios, this.basePath));
    }
}
