// @mui
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Container, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { MotionInView, varFade } from '../../components/animate';

// _mock_
// components
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(8, 0),
  backgroundColor: theme.palette.grey[900],
}));

const ContentStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
  position: 'relative',
  marginBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    height: '100%',
    marginBottom: 0,
    textAlign: 'left',
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    width: '100%',
  },
}));

// ----------------------------------------------------------------------

export default function Faq() {
  const { t } = useTranslation();
  return (
    <RootStyle>
      <Container>
        <ContentStyle>
          <Container>
            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={{ xs: 8, md: 3 }}>
              <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'center', md: 'left' } }}>

                <Box
                  sx={{
                    textAlign: 'left',
                    mb: { xs: 10, md: 4 },
                  }}
                >
                  <MotionInView variants={varFade().inUp}>
                    <Typography variant="h2" sx={{
                      color: 'common.white',
                      whiteSpace: 'nowrap'
                    }}>{t('landingPage.faq.title')}
                    </Typography>
                  </MotionInView>
                  <MotionInView variants={varFade().inDown}>
                    <div className='horizontal-line' />
                    <Typography component="div" variant="overline" sx={{ mb: 2, color: 'text.disabled' }} />
                  </MotionInView>
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              alignItems="center"
              justifyContent="space-between"
              spacing={{ xs: 8, md: 3 }}
            >
              <Grid item xs={12} md={8} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                <Accordion sx={{ backgroundColor: '#161C24', borderTop: 1, borderRadius: 0 }} style={{ boxShadow: 'none' }}>
                  <AccordionSummary
                    sx={{ boxShadow: 0 }}
                  >
                    <Box component="img" src="/images/expand.svg" width={20} height={20} />
                    <Typography sx={{ color: '#ffffff', marginX: 2, textAlign: 'left' }}>{t('landingPage.faq.becomeTitle')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'left' }}>{t('landingPage.faq.becomeDescription')}</Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{ backgroundColor: '#161C24', borderTop: 1, borderRadius: 0 }} style={{ boxShadow: 'none' }}>
                  <AccordionSummary
                  >
                    <Box component="img" src="/images/expand.svg" width={20} height={20} />

                    <Typography sx={{ color: '#ffffff', marginX: 2, textAlign: 'left' }}>{t('landingPage.faq.handleTitle')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'left' }}>
                      {t('landingPage.faq.handleDescription')}</Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{ backgroundColor: '#161C24', borderTop: 1 }} style={{ boxShadow: 'none' }}>
                  <AccordionSummary
                  >
                    <Box component="img" src="/images/expand.svg" width={20} height={20} />
                    <Typography sx={{ color: '#ffffff', marginX: 2, textAlign: 'left' }}>{t('landingPage.faq.companyTitle')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'left' }}>{t('landingPage.faq.companyDescription')}</Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{ backgroundColor: '#161C24', borderTop: 1 }} style={{ boxShadow: 'none' }}>
                  <AccordionSummary
                  >
                    <Box component="img" src="/images/expand.svg" width={20} height={20} />
                    <Typography sx={{ color: '#ffffff', marginX: 2, textAlign: 'left' }} >{t('landingPage.faq.incomeTitle')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'left' }}>{t('landingPage.faq.incomeDescription')}</Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{ backgroundColor: '#161C24', borderTop: 1 }} style={{ boxShadow: 'none' }}>
                  <AccordionSummary
                  >
                    <Box component="img" src="/images/expand.svg" width={20} height={20} />
                    <Typography sx={{ color: '#ffffff', marginX: 2, textAlign: 'left' }}>{t('landingPage.faq.tradeTitle')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'left' }}>{t('landingPage.faq.tradeDescription')}</Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{ backgroundColor: '#161C24', borderTop: 1 }} style={{ boxShadow: 'none' }}>
                  <AccordionSummary
                  >
                    <Box component="img" src="/images/expand.svg" width={20} height={20} />
                    <Typography sx={{ color: '#ffffff', marginX: 2, textAlign: 'left' }}>{t('landingPage.faq.withdrawTitle')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'left' }}>{t('landingPage.faq.withdrawDescription')}</Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{ backgroundColor: '#161C24', borderTop: 1 }} style={{ boxShadow: 'none' }}>
                  <AccordionSummary
                  >
                    <Box component="img" src="/images/expand.svg" width={20} height={20} />
                    <Typography sx={{ color: '#ffffff', marginX: 2, textAlign: 'left' }}>{t('landingPage.faq.currenciesTitle')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'left' }}>{t('landingPage.faq.currenciesDescription')}</Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{ backgroundColor: '#161C24', borderTop: 1 }} style={{ boxShadow: 'none' }}>
                  <AccordionSummary
                  >
                    <Box component="img" src="/images/expand.svg" width={20} height={20} />
                    <Typography sx={{ color: '#ffffff', marginX: 2, textAlign: 'left' }}>{t('landingPage.faq.accountTitle')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'left' }}>{t('landingPage.faq.accountDescription')}</Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{ backgroundColor: '#161C24', borderTop: 1 }} style={{ boxShadow: 'none' }}>
                  <AccordionSummary
                  >
                    <Box component="img" src="/images/expand.svg" width={20} height={20} />
                    <Typography sx={{ color: '#ffffff', marginX: 2, textAlign: 'left' }}>{t('landingPage.faq.possibleTitle')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'left' }}>{t('landingPage.faq.possibleDescription')}</Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{ backgroundColor: '#161C24', borderTop: 1 }} style={{ boxShadow: 'none' }}>
                  <AccordionSummary
                  >
                    <Box component="img" src="/images/expand.svg" width={20} height={20} />
                    <Typography sx={{ color: '#ffffff', marginX: 2, textAlign: 'left' }}>{t('landingPage.faq.depositTitle')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'left' }}>{t('landingPage.faq.depositDescription')}</Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion sx={{ backgroundColor: '#161C24', borderTop: 1 }} style={{ boxShadow: 'none' }}>
                  <AccordionSummary>
                    <Box component="img" src="/images/expand.svg" width={20} height={20} />
                    <Typography sx={{ color: '#ffffff', marginX: 2, textAlign: 'left' }}>{t('landingPage.faq.profitTitle')}</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography sx={{ color: 'rgba(255, 255, 255, 0.7)', textAlign: 'left' }}>{t('landingPage.faq.profitDescription')}</Typography>
                  </AccordionDetails>
                </Accordion>

              </Grid>

              <Grid item xs={12} md={4} sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                <div style={{ position: 'relative' }}>
                  <Box component="img" src="/images/man_faqs.png" />
                  <Button className="faqs-support-button" href="#contact" style={{ position: 'absolute' }}>
                    <Box component="img" src="/images/button_faqs.svg" width={75} />
                  </Button>
                </div>
              </Grid>

            </Grid>
          </Container>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
