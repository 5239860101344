/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ForgotPasswordRequest } from '../model';
/**
 * ForgotPasswordRestAdapterApi - axios parameter creator
 * @export
 */
export const ForgotPasswordRestAdapterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {ForgotPasswordRequest} forgotPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword1: async (forgotPasswordRequest: ForgotPasswordRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'forgotPasswordRequest' is not null or undefined
            assertParamExists('forgotPassword1', 'forgotPasswordRequest', forgotPasswordRequest)
            const localVarPath = `/forgot-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(forgotPasswordRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ForgotPasswordRestAdapterApi - functional programming interface
 * @export
 */
export const ForgotPasswordRestAdapterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ForgotPasswordRestAdapterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {ForgotPasswordRequest} forgotPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async forgotPassword1(forgotPasswordRequest: ForgotPasswordRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.forgotPassword1(forgotPasswordRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ForgotPasswordRestAdapterApi - factory interface
 * @export
 */
export const ForgotPasswordRestAdapterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ForgotPasswordRestAdapterApiFp(configuration)
    return {
        /**
         * 
         * @param {ForgotPasswordRequest} forgotPasswordRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        forgotPassword1(forgotPasswordRequest: ForgotPasswordRequest, options?: any): AxiosPromise<object> {
            return localVarFp.forgotPassword1(forgotPasswordRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for forgotPassword1 operation in ForgotPasswordRestAdapterApi.
 * @export
 * @interface ForgotPasswordRestAdapterApiForgotPassword1Request
 */
export interface ForgotPasswordRestAdapterApiForgotPassword1Request {
    /**
     * 
     * @type {ForgotPasswordRequest}
     * @memberof ForgotPasswordRestAdapterApiForgotPassword1
     */
    readonly forgotPasswordRequest: ForgotPasswordRequest
}

/**
 * ForgotPasswordRestAdapterApi - object-oriented interface
 * @export
 * @class ForgotPasswordRestAdapterApi
 * @extends {BaseAPI}
 */
export class ForgotPasswordRestAdapterApi extends BaseAPI {
    /**
     * 
     * @param {ForgotPasswordRestAdapterApiForgotPassword1Request} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForgotPasswordRestAdapterApi
     */
    public forgotPassword1(requestParameters: ForgotPasswordRestAdapterApiForgotPassword1Request, options?: any) {
        return ForgotPasswordRestAdapterApiFp(this.configuration).forgotPassword1(requestParameters.forgotPasswordRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
