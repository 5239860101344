/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { StringWrapperRequest } from '../model';
// @ts-ignore
import { UserDTO } from '../model';
/**
 * RegistrationRestAdapterApi - axios parameter creator
 * @export
 */
export const RegistrationRestAdapterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {string} [username] 
         * @param {string} [password] 
         * @param {string} [countryCode] 
         * @param {string} [birthday] 
         * @param {string} [name] 
         * @param {string} [surname] 
         * @param {string} [phone] 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: async (token?: string, username?: string, password?: string, countryCode?: string, birthday?: string, name?: string, surname?: string, phone?: string, email?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (token !== undefined) { 
                localVarFormParams.append('token', token as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.append('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.append('password', password as any);
            }
    
            if (countryCode !== undefined) { 
                localVarFormParams.append('countryCode', countryCode as any);
            }
    
            if (birthday !== undefined) { 
                localVarFormParams.append('birthday', birthday as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('name', name as any);
            }
    
            if (surname !== undefined) { 
                localVarFormParams.append('surname', surname as any);
            }
    
            if (phone !== undefined) { 
                localVarFormParams.append('phone', phone as any);
            }
    
            if (email !== undefined) { 
                localVarFormParams.append('email', email as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {StringWrapperRequest} stringWrapperRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendConfirmation: async (stringWrapperRequest: StringWrapperRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'stringWrapperRequest' is not null or undefined
            assertParamExists('resendConfirmation', 'stringWrapperRequest', stringWrapperRequest)
            const localVarPath = `/resend-confirmation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(stringWrapperRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegistrationRestAdapterApi - functional programming interface
 * @export
 */
export const RegistrationRestAdapterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegistrationRestAdapterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {string} [username] 
         * @param {string} [password] 
         * @param {string} [countryCode] 
         * @param {string} [birthday] 
         * @param {string} [name] 
         * @param {string} [surname] 
         * @param {string} [phone] 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async register(token?: string, username?: string, password?: string, countryCode?: string, birthday?: string, name?: string, surname?: string, phone?: string, email?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.register(token, username, password, countryCode, birthday, name, surname, phone, email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {StringWrapperRequest} stringWrapperRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resendConfirmation(stringWrapperRequest: StringWrapperRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resendConfirmation(stringWrapperRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RegistrationRestAdapterApi - factory interface
 * @export
 */
export const RegistrationRestAdapterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegistrationRestAdapterApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [token] 
         * @param {string} [username] 
         * @param {string} [password] 
         * @param {string} [countryCode] 
         * @param {string} [birthday] 
         * @param {string} [name] 
         * @param {string} [surname] 
         * @param {string} [phone] 
         * @param {string} [email] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(token?: string, username?: string, password?: string, countryCode?: string, birthday?: string, name?: string, surname?: string, phone?: string, email?: string, options?: any): AxiosPromise<UserDTO> {
            return localVarFp.register(token, username, password, countryCode, birthday, name, surname, phone, email, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {StringWrapperRequest} stringWrapperRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resendConfirmation(stringWrapperRequest: StringWrapperRequest, options?: any): AxiosPromise<string> {
            return localVarFp.resendConfirmation(stringWrapperRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for register operation in RegistrationRestAdapterApi.
 * @export
 * @interface RegistrationRestAdapterApiRegisterRequest
 */
export interface RegistrationRestAdapterApiRegisterRequest {
    /**
     * 
     * @type {string}
     * @memberof RegistrationRestAdapterApiRegister
     */
    readonly token?: string

    /**
     * 
     * @type {string}
     * @memberof RegistrationRestAdapterApiRegister
     */
    readonly username?: string

    /**
     * 
     * @type {string}
     * @memberof RegistrationRestAdapterApiRegister
     */
    readonly password?: string

    /**
     * 
     * @type {string}
     * @memberof RegistrationRestAdapterApiRegister
     */
    readonly countryCode?: string

    /**
     * 
     * @type {string}
     * @memberof RegistrationRestAdapterApiRegister
     */
    readonly birthday?: string

    /**
     * 
     * @type {string}
     * @memberof RegistrationRestAdapterApiRegister
     */
    readonly name?: string

    /**
     * 
     * @type {string}
     * @memberof RegistrationRestAdapterApiRegister
     */
    readonly surname?: string

    /**
     * 
     * @type {string}
     * @memberof RegistrationRestAdapterApiRegister
     */
    readonly phone?: string

    /**
     * 
     * @type {string}
     * @memberof RegistrationRestAdapterApiRegister
     */
    readonly email?: string
}

/**
 * Request parameters for resendConfirmation operation in RegistrationRestAdapterApi.
 * @export
 * @interface RegistrationRestAdapterApiResendConfirmationRequest
 */
export interface RegistrationRestAdapterApiResendConfirmationRequest {
    /**
     * 
     * @type {StringWrapperRequest}
     * @memberof RegistrationRestAdapterApiResendConfirmation
     */
    readonly stringWrapperRequest: StringWrapperRequest
}

/**
 * RegistrationRestAdapterApi - object-oriented interface
 * @export
 * @class RegistrationRestAdapterApi
 * @extends {BaseAPI}
 */
export class RegistrationRestAdapterApi extends BaseAPI {
    /**
     * 
     * @param {RegistrationRestAdapterApiRegisterRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationRestAdapterApi
     */
    public register(requestParameters: RegistrationRestAdapterApiRegisterRequest = {}, options?: any) {
        return RegistrationRestAdapterApiFp(this.configuration).register(requestParameters.token, requestParameters.username, requestParameters.password, requestParameters.countryCode, requestParameters.birthday, requestParameters.name, requestParameters.surname, requestParameters.phone, requestParameters.email, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {RegistrationRestAdapterApiResendConfirmationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegistrationRestAdapterApi
     */
    public resendConfirmation(requestParameters: RegistrationRestAdapterApiResendConfirmationRequest, options?: any) {
        return RegistrationRestAdapterApiFp(this.configuration).resendConfirmation(requestParameters.stringWrapperRequest, options).then((request) => request(this.axios, this.basePath));
    }
}
