import * as Yup from 'yup';
import i18next from 'i18next';

export const RegisterDataSchema = Yup.object().shape({
  terms: Yup.boolean().isTrue(i18next.t('yup.terms')),
  name: Yup.string()
    .min(2, i18next.t('yup.short'))
    .max(50, i18next.t('yup.long'))
    .required(i18next.t('yup.firstNameIsRequired')),
  surname: Yup.string()
    .min(2, i18next.t('yup.short'))
    .max(50, i18next.t('yup.long'))
    .required(i18next.t('yup.lastNameIsRequired')),
  username: Yup.string()
    .min(2, i18next.t('yup.short'))
    .max(50, i18next.t('yup.long'))
    .required(i18next.t('yup.usernameIsRequired')),
  email: Yup.string()
    .email(i18next.t('yup.emailMustBeValid'))
    .required(i18next.t('yup.emailIsRequired')),
  birthday: Yup.string().required(i18next.t('yup.birthdayIsRequired')),
  phone: Yup.string().required(i18next.t('yup.phoneIsRequired')),
  countryCode: Yup.string().required(i18next.t('yup.countryIsRequired')),
  password: Yup.string()
    .required(i18next.t('yup.passwordIsRequired'))
    .min(6, i18next.t('yup.passwordMin', { n: 6 })),
  confirmPassword: Yup.string()
    .required(i18next.t('yup.confirmPasswordIsRequired'))
    .oneOf([Yup.ref('password')], i18next.t('yup.passwordMustBeMatch')),
});

export const UpdatePersonalDataSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, i18next.t('yup.short'))
    .max(50, i18next.t('yup.long'))
    .required(i18next.t('yup.firstNameIsRequired')),
  surname: Yup.string()
    .min(2, i18next.t('yup.short'))
    .max(50, i18next.t('yup.long'))
    .required(i18next.t('yup.lastNameIsRequired')),
  username: Yup.string()
    .min(2, i18next.t('yup.short'))
    .max(50, i18next.t('yup.long'))
    .required(i18next.t('yup.usernameIsRequired')),
  email: Yup.string()
    .email(i18next.t('yup.emailMustBeValid'))
    .required(i18next.t('yup.emailIsRequired')),
  birthday: Yup.string().required(i18next.t('yup.birthdayIsRequired')),
  phone: Yup.string().required(i18next.t('yup.phoneIsRequired')),
  countryCode: Yup.string().required(i18next.t('yup.countryIsRequired')),
});

export const RegisterDocumentSchema = Yup.object().shape({
  // personalDocument: Yup.object()
  //   .shape({
  //     name: Yup.string().required(),
  //   })
  //   .label('File'),
  // proofOfAddressDocument: Yup.object()
  //   .shape({
  //     name: Yup.string().required(),
  //   })
  //   .label('File'),
});
