/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AbstractPageableResponseBonusIncomeRecord } from '../model';
// @ts-ignore
import { AbstractPageableResponseBonusReinvestRecord } from '../model';
// @ts-ignore
import { ReferralBonusStatisticResponse } from '../model';
// @ts-ignore
import { ReinvestRequest } from '../model';
/**
 * ManageReferralBonusesRestAdapterApi - axios parameter creator
 * @export
 */
export const ManageReferralBonusesRestAdapterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [localDateFrom] 
         * @param {string} [localDateTo] 
         * @param {string} [affiliateName] 
         * @param {string} [countryCode] 
         * @param {'FIRST' | 'SECOND' | 'THIRD' | 'COMPANY_FIRST' | 'COMPANY_SECOND' | 'COMPANY_THIRD' | 'SPONSOR_BONUS' | 'ADMIN_BONUS' | 'SPONSOR_OUTCOME_BONUS' | 'ADMIN_OUTCOME_BONUS' | 'SPONSOR_VIP_OUTCOME_BONUS' | 'ADMIN_VIP_OUTCOME_BONUS' | 'KEEP' | 'RESERVE_FUND'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incomeHistory: async (page?: number, size?: number, sort?: Array<string>, localDateFrom?: string, localDateTo?: string, affiliateName?: string, countryCode?: string, type?: 'FIRST' | 'SECOND' | 'THIRD' | 'COMPANY_FIRST' | 'COMPANY_SECOND' | 'COMPANY_THIRD' | 'SPONSOR_BONUS' | 'ADMIN_BONUS' | 'SPONSOR_OUTCOME_BONUS' | 'ADMIN_OUTCOME_BONUS' | 'SPONSOR_VIP_OUTCOME_BONUS' | 'ADMIN_VIP_OUTCOME_BONUS' | 'KEEP' | 'RESERVE_FUND', options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/referral-bonuses/income-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (localDateFrom !== undefined) {
                localVarQueryParameter['localDateFrom'] = (localDateFrom as any instanceof Date) ?
                    (localDateFrom as any).toISOString().substr(0,10) :
                    localDateFrom;
            }

            if (localDateTo !== undefined) {
                localVarQueryParameter['localDateTo'] = (localDateTo as any instanceof Date) ?
                    (localDateTo as any).toISOString().substr(0,10) :
                    localDateTo;
            }

            if (affiliateName !== undefined) {
                localVarQueryParameter['affiliateName'] = affiliateName;
            }

            if (countryCode !== undefined) {
                localVarQueryParameter['countryCode'] = countryCode;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ReinvestRequest} reinvestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reinvest: async (reinvestRequest: ReinvestRequest, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'reinvestRequest' is not null or undefined
            assertParamExists('reinvest', 'reinvestRequest', reinvestRequest)
            const localVarPath = `/referral-bonuses/reinvest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(reinvestRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [localDateFrom] 
         * @param {string} [localDateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reinvestHistory: async (page?: number, size?: number, sort?: Array<string>, localDateFrom?: string, localDateTo?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/referral-bonuses/reinvest-history`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }

            if (sort) {
                localVarQueryParameter['sort'] = sort;
            }

            if (localDateFrom !== undefined) {
                localVarQueryParameter['localDateFrom'] = (localDateFrom as any instanceof Date) ?
                    (localDateFrom as any).toISOString().substr(0,10) :
                    localDateFrom;
            }

            if (localDateTo !== undefined) {
                localVarQueryParameter['localDateTo'] = (localDateTo as any instanceof Date) ?
                    (localDateTo as any).toISOString().substr(0,10) :
                    localDateTo;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        totalStatistic: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/referral-bonuses/total-statistic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ManageReferralBonusesRestAdapterApi - functional programming interface
 * @export
 */
export const ManageReferralBonusesRestAdapterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ManageReferralBonusesRestAdapterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [localDateFrom] 
         * @param {string} [localDateTo] 
         * @param {string} [affiliateName] 
         * @param {string} [countryCode] 
         * @param {'FIRST' | 'SECOND' | 'THIRD' | 'COMPANY_FIRST' | 'COMPANY_SECOND' | 'COMPANY_THIRD' | 'SPONSOR_BONUS' | 'ADMIN_BONUS' | 'SPONSOR_OUTCOME_BONUS' | 'ADMIN_OUTCOME_BONUS' | 'SPONSOR_VIP_OUTCOME_BONUS' | 'ADMIN_VIP_OUTCOME_BONUS' | 'KEEP' | 'RESERVE_FUND'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async incomeHistory(page?: number, size?: number, sort?: Array<string>, localDateFrom?: string, localDateTo?: string, affiliateName?: string, countryCode?: string, type?: 'FIRST' | 'SECOND' | 'THIRD' | 'COMPANY_FIRST' | 'COMPANY_SECOND' | 'COMPANY_THIRD' | 'SPONSOR_BONUS' | 'ADMIN_BONUS' | 'SPONSOR_OUTCOME_BONUS' | 'ADMIN_OUTCOME_BONUS' | 'SPONSOR_VIP_OUTCOME_BONUS' | 'ADMIN_VIP_OUTCOME_BONUS' | 'KEEP' | 'RESERVE_FUND', options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractPageableResponseBonusIncomeRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.incomeHistory(page, size, sort, localDateFrom, localDateTo, affiliateName, countryCode, type, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ReinvestRequest} reinvestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reinvest(reinvestRequest: ReinvestRequest, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reinvest(reinvestRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [localDateFrom] 
         * @param {string} [localDateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async reinvestHistory(page?: number, size?: number, sort?: Array<string>, localDateFrom?: string, localDateTo?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AbstractPageableResponseBonusReinvestRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.reinvestHistory(page, size, sort, localDateFrom, localDateTo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async totalStatistic(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ReferralBonusStatisticResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.totalStatistic(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ManageReferralBonusesRestAdapterApi - factory interface
 * @export
 */
export const ManageReferralBonusesRestAdapterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ManageReferralBonusesRestAdapterApiFp(configuration)
    return {
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [localDateFrom] 
         * @param {string} [localDateTo] 
         * @param {string} [affiliateName] 
         * @param {string} [countryCode] 
         * @param {'FIRST' | 'SECOND' | 'THIRD' | 'COMPANY_FIRST' | 'COMPANY_SECOND' | 'COMPANY_THIRD' | 'SPONSOR_BONUS' | 'ADMIN_BONUS' | 'SPONSOR_OUTCOME_BONUS' | 'ADMIN_OUTCOME_BONUS' | 'SPONSOR_VIP_OUTCOME_BONUS' | 'ADMIN_VIP_OUTCOME_BONUS' | 'KEEP' | 'RESERVE_FUND'} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        incomeHistory(page?: number, size?: number, sort?: Array<string>, localDateFrom?: string, localDateTo?: string, affiliateName?: string, countryCode?: string, type?: 'FIRST' | 'SECOND' | 'THIRD' | 'COMPANY_FIRST' | 'COMPANY_SECOND' | 'COMPANY_THIRD' | 'SPONSOR_BONUS' | 'ADMIN_BONUS' | 'SPONSOR_OUTCOME_BONUS' | 'ADMIN_OUTCOME_BONUS' | 'SPONSOR_VIP_OUTCOME_BONUS' | 'ADMIN_VIP_OUTCOME_BONUS' | 'KEEP' | 'RESERVE_FUND', options?: any): AxiosPromise<AbstractPageableResponseBonusIncomeRecord> {
            return localVarFp.incomeHistory(page, size, sort, localDateFrom, localDateTo, affiliateName, countryCode, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ReinvestRequest} reinvestRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reinvest(reinvestRequest: ReinvestRequest, options?: any): AxiosPromise<object> {
            return localVarFp.reinvest(reinvestRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [page] Zero-based page index (0..N)
         * @param {number} [size] The size of the page to be returned
         * @param {Array<string>} [sort] Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
         * @param {string} [localDateFrom] 
         * @param {string} [localDateTo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reinvestHistory(page?: number, size?: number, sort?: Array<string>, localDateFrom?: string, localDateTo?: string, options?: any): AxiosPromise<AbstractPageableResponseBonusReinvestRecord> {
            return localVarFp.reinvestHistory(page, size, sort, localDateFrom, localDateTo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        totalStatistic(options?: any): AxiosPromise<ReferralBonusStatisticResponse> {
            return localVarFp.totalStatistic(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for incomeHistory operation in ManageReferralBonusesRestAdapterApi.
 * @export
 * @interface ManageReferralBonusesRestAdapterApiIncomeHistoryRequest
 */
export interface ManageReferralBonusesRestAdapterApiIncomeHistoryRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof ManageReferralBonusesRestAdapterApiIncomeHistory
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof ManageReferralBonusesRestAdapterApiIncomeHistory
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof ManageReferralBonusesRestAdapterApiIncomeHistory
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof ManageReferralBonusesRestAdapterApiIncomeHistory
     */
    readonly localDateFrom?: string

    /**
     * 
     * @type {string}
     * @memberof ManageReferralBonusesRestAdapterApiIncomeHistory
     */
    readonly localDateTo?: string

    /**
     * 
     * @type {string}
     * @memberof ManageReferralBonusesRestAdapterApiIncomeHistory
     */
    readonly affiliateName?: string

    /**
     * 
     * @type {string}
     * @memberof ManageReferralBonusesRestAdapterApiIncomeHistory
     */
    readonly countryCode?: string

    /**
     * 
     * @type {'FIRST' | 'SECOND' | 'THIRD' | 'COMPANY_FIRST' | 'COMPANY_SECOND' | 'COMPANY_THIRD' | 'SPONSOR_BONUS' | 'ADMIN_BONUS' | 'SPONSOR_OUTCOME_BONUS' | 'ADMIN_OUTCOME_BONUS' | 'SPONSOR_VIP_OUTCOME_BONUS' | 'ADMIN_VIP_OUTCOME_BONUS' | 'KEEP' | 'RESERVE_FUND'}
     * @memberof ManageReferralBonusesRestAdapterApiIncomeHistory
     */
    readonly type?: 'FIRST' | 'SECOND' | 'THIRD' | 'COMPANY_FIRST' | 'COMPANY_SECOND' | 'COMPANY_THIRD' | 'SPONSOR_BONUS' | 'ADMIN_BONUS' | 'SPONSOR_OUTCOME_BONUS' | 'ADMIN_OUTCOME_BONUS' | 'SPONSOR_VIP_OUTCOME_BONUS' | 'ADMIN_VIP_OUTCOME_BONUS' | 'KEEP' | 'RESERVE_FUND'
}

/**
 * Request parameters for reinvest operation in ManageReferralBonusesRestAdapterApi.
 * @export
 * @interface ManageReferralBonusesRestAdapterApiReinvestRequest
 */
export interface ManageReferralBonusesRestAdapterApiReinvestRequest {
    /**
     * 
     * @type {ReinvestRequest}
     * @memberof ManageReferralBonusesRestAdapterApiReinvest
     */
    readonly reinvestRequest: ReinvestRequest
}

/**
 * Request parameters for reinvestHistory operation in ManageReferralBonusesRestAdapterApi.
 * @export
 * @interface ManageReferralBonusesRestAdapterApiReinvestHistoryRequest
 */
export interface ManageReferralBonusesRestAdapterApiReinvestHistoryRequest {
    /**
     * Zero-based page index (0..N)
     * @type {number}
     * @memberof ManageReferralBonusesRestAdapterApiReinvestHistory
     */
    readonly page?: number

    /**
     * The size of the page to be returned
     * @type {number}
     * @memberof ManageReferralBonusesRestAdapterApiReinvestHistory
     */
    readonly size?: number

    /**
     * Sorting criteria in the format: property(,asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     * @type {Array<string>}
     * @memberof ManageReferralBonusesRestAdapterApiReinvestHistory
     */
    readonly sort?: Array<string>

    /**
     * 
     * @type {string}
     * @memberof ManageReferralBonusesRestAdapterApiReinvestHistory
     */
    readonly localDateFrom?: string

    /**
     * 
     * @type {string}
     * @memberof ManageReferralBonusesRestAdapterApiReinvestHistory
     */
    readonly localDateTo?: string
}

/**
 * ManageReferralBonusesRestAdapterApi - object-oriented interface
 * @export
 * @class ManageReferralBonusesRestAdapterApi
 * @extends {BaseAPI}
 */
export class ManageReferralBonusesRestAdapterApi extends BaseAPI {
    /**
     * 
     * @param {ManageReferralBonusesRestAdapterApiIncomeHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManageReferralBonusesRestAdapterApi
     */
    public incomeHistory(requestParameters: ManageReferralBonusesRestAdapterApiIncomeHistoryRequest = {}, options?: any) {
        return ManageReferralBonusesRestAdapterApiFp(this.configuration).incomeHistory(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.localDateFrom, requestParameters.localDateTo, requestParameters.affiliateName, requestParameters.countryCode, requestParameters.type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManageReferralBonusesRestAdapterApiReinvestRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManageReferralBonusesRestAdapterApi
     */
    public reinvest(requestParameters: ManageReferralBonusesRestAdapterApiReinvestRequest, options?: any) {
        return ManageReferralBonusesRestAdapterApiFp(this.configuration).reinvest(requestParameters.reinvestRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManageReferralBonusesRestAdapterApiReinvestHistoryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManageReferralBonusesRestAdapterApi
     */
    public reinvestHistory(requestParameters: ManageReferralBonusesRestAdapterApiReinvestHistoryRequest = {}, options?: any) {
        return ManageReferralBonusesRestAdapterApiFp(this.configuration).reinvestHistory(requestParameters.page, requestParameters.size, requestParameters.sort, requestParameters.localDateFrom, requestParameters.localDateTo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ManageReferralBonusesRestAdapterApi
     */
    public totalStatistic(options?: any) {
        return ManageReferralBonusesRestAdapterApiFp(this.configuration).totalStatistic(options).then((request) => request(this.axios, this.basePath));
    }
}
