// @mui
import {
  Autocomplete,
  InputAdornment,
  Popper,
  PopperProps,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadSimplifiedAffiliate } from 'src/api';
import { AffiliateNetworkNodeSimplifiedDTO } from 'src/api/generated/common';
// components
import Iconify from 'src/components/Iconify';
import { useDebounce } from 'src/hooks/useDebounce';

// ----------------------------------------------------------------------

export type AffiliateSearchDataType = {
  fullName?: string;
};

type Props = {
  onSearch: (user?: AffiliateNetworkNodeSimplifiedDTO) => void;
};

export default function AffiliateSearchBar({ onSearch }: Props) {
  const [userList, setUserList] = useState<AffiliateNetworkNodeSimplifiedDTO[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const fullNameDebounce = useDebounce<string>(searchValue.trim(), 600);

  useEffect(() => {
    const loadUsers = async (fullName: string) => {
      const response = await loadSimplifiedAffiliate({ fullName });
      setUserList(response);
    };

    loadUsers(fullNameDebounce);
  }, [fullNameDebounce]);

  const { t } = useTranslation();

  const PopperMy = function (props: PopperProps) {
    return <Popper {...props} style={{ minWidth: 400 }} placement="bottom-start" />;
  };
  return (
    <TableRow>
      <TableCell colSpan={6}>
        <Autocomplete
          sx={{ minWidth: { xs: 160, md: 340, xl: 400 } }}
          filterOptions={(x) => x}
          autoComplete
          includeInputInList
          PopperComponent={PopperMy}
          filterSelectedOptions
          onChange={(event: any, newValue) => {
            if (newValue) onSearch(newValue as AffiliateNetworkNodeSimplifiedDTO);
            else onSearch(undefined);
          }}
          onInputChange={(event, newInputValue) => {
            setSearchValue(newInputValue);
          }}
          getOptionLabel={(option) => ` ${option.fullName}`}
          options={userList}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('field.searchName')}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify
                      icon={'eva:search-fill'}
                      sx={{ color: 'text.disabled', width: 20, height: 20 }}
                    />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </TableCell>
    </TableRow>
  );
}
