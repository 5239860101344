import {
  InboxMessageDTO,
  LoadInboxMessagesRestAdapterApi,
  LoadInboxMessagesRestAdapterApiLoadInboxContentRequest,
} from './generated/common';

export const loadLastNews = async (): Promise<Array<InboxMessageDTO>> => {
  const api = new LoadInboxMessagesRestAdapterApi();

  return (await api.loadAllInboxMessages()).data;
};

export const loadLastNewsContent = async (
  requestParameters: LoadInboxMessagesRestAdapterApiLoadInboxContentRequest
): Promise<InboxMessageDTO> => {
  const api = new LoadInboxMessagesRestAdapterApi();

  return (await api.loadInboxContent(requestParameters)).data;
};
