/* tslint:disable */
/* eslint-disable */
/**
 * My API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { AffiliateNetworkHeadResponse } from '../model';
// @ts-ignore
import { AffiliateNetworkNodeDTO } from '../model';
// @ts-ignore
import { AffiliateNetworkNodeSimplifiedDTO } from '../model';
/**
 * LoadAffiliateTreeRestAdapterApi - axios parameter creator
 * @export
 */
export const LoadAffiliateTreeRestAdapterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} affiliateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAffiliateSearchResult: async (affiliateId: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'affiliateId' is not null or undefined
            assertParamExists('getAffiliateSearchResult', 'affiliateId', affiliateId)
            const localVarPath = `/affiliate/get-affiliate-search-result`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (affiliateId !== undefined) {
                localVarQueryParameter['affiliateId'] = affiliateId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} [line] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFirstLine: async (userId: number, line?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('getFirstLine', 'userId', userId)
            const localVarPath = `/affiliate/get-first-line`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (userId !== undefined) {
                localVarQueryParameter['userId'] = userId;
            }

            if (line !== undefined) {
                localVarQueryParameter['line'] = line;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHead: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/affiliate/get-head`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [fullName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimplified: async (fullName?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/affiliate/get-simplified`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (fullName !== undefined) {
                localVarQueryParameter['fullName'] = fullName;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTop: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/affiliate/get-top`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LoadAffiliateTreeRestAdapterApi - functional programming interface
 * @export
 */
export const LoadAffiliateTreeRestAdapterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LoadAffiliateTreeRestAdapterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {number} affiliateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAffiliateSearchResult(affiliateId: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AffiliateNetworkNodeDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAffiliateSearchResult(affiliateId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} [line] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFirstLine(userId: number, line?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AffiliateNetworkNodeDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFirstLine(userId, line, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getHead(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AffiliateNetworkHeadResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getHead(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [fullName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSimplified(fullName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AffiliateNetworkNodeSimplifiedDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSimplified(fullName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTop(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AffiliateNetworkNodeDTO>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTop(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * LoadAffiliateTreeRestAdapterApi - factory interface
 * @export
 */
export const LoadAffiliateTreeRestAdapterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LoadAffiliateTreeRestAdapterApiFp(configuration)
    return {
        /**
         * 
         * @param {number} affiliateId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAffiliateSearchResult(affiliateId: number, options?: any): AxiosPromise<Array<AffiliateNetworkNodeDTO>> {
            return localVarFp.getAffiliateSearchResult(affiliateId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} userId 
         * @param {number} [line] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFirstLine(userId: number, line?: number, options?: any): AxiosPromise<Array<AffiliateNetworkNodeDTO>> {
            return localVarFp.getFirstLine(userId, line, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHead(options?: any): AxiosPromise<AffiliateNetworkHeadResponse> {
            return localVarFp.getHead(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [fullName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSimplified(fullName?: string, options?: any): AxiosPromise<Array<AffiliateNetworkNodeSimplifiedDTO>> {
            return localVarFp.getSimplified(fullName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTop(options?: any): AxiosPromise<Array<AffiliateNetworkNodeDTO>> {
            return localVarFp.getTop(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for getAffiliateSearchResult operation in LoadAffiliateTreeRestAdapterApi.
 * @export
 * @interface LoadAffiliateTreeRestAdapterApiGetAffiliateSearchResultRequest
 */
export interface LoadAffiliateTreeRestAdapterApiGetAffiliateSearchResultRequest {
    /**
     * 
     * @type {number}
     * @memberof LoadAffiliateTreeRestAdapterApiGetAffiliateSearchResult
     */
    readonly affiliateId: number
}

/**
 * Request parameters for getFirstLine operation in LoadAffiliateTreeRestAdapterApi.
 * @export
 * @interface LoadAffiliateTreeRestAdapterApiGetFirstLineRequest
 */
export interface LoadAffiliateTreeRestAdapterApiGetFirstLineRequest {
    /**
     * 
     * @type {number}
     * @memberof LoadAffiliateTreeRestAdapterApiGetFirstLine
     */
    readonly userId: number

    /**
     * 
     * @type {number}
     * @memberof LoadAffiliateTreeRestAdapterApiGetFirstLine
     */
    readonly line?: number
}

/**
 * Request parameters for getSimplified operation in LoadAffiliateTreeRestAdapterApi.
 * @export
 * @interface LoadAffiliateTreeRestAdapterApiGetSimplifiedRequest
 */
export interface LoadAffiliateTreeRestAdapterApiGetSimplifiedRequest {
    /**
     * 
     * @type {string}
     * @memberof LoadAffiliateTreeRestAdapterApiGetSimplified
     */
    readonly fullName?: string
}

/**
 * LoadAffiliateTreeRestAdapterApi - object-oriented interface
 * @export
 * @class LoadAffiliateTreeRestAdapterApi
 * @extends {BaseAPI}
 */
export class LoadAffiliateTreeRestAdapterApi extends BaseAPI {
    /**
     * 
     * @param {LoadAffiliateTreeRestAdapterApiGetAffiliateSearchResultRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadAffiliateTreeRestAdapterApi
     */
    public getAffiliateSearchResult(requestParameters: LoadAffiliateTreeRestAdapterApiGetAffiliateSearchResultRequest, options?: any) {
        return LoadAffiliateTreeRestAdapterApiFp(this.configuration).getAffiliateSearchResult(requestParameters.affiliateId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadAffiliateTreeRestAdapterApiGetFirstLineRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadAffiliateTreeRestAdapterApi
     */
    public getFirstLine(requestParameters: LoadAffiliateTreeRestAdapterApiGetFirstLineRequest, options?: any) {
        return LoadAffiliateTreeRestAdapterApiFp(this.configuration).getFirstLine(requestParameters.userId, requestParameters.line, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadAffiliateTreeRestAdapterApi
     */
    public getHead(options?: any) {
        return LoadAffiliateTreeRestAdapterApiFp(this.configuration).getHead(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {LoadAffiliateTreeRestAdapterApiGetSimplifiedRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadAffiliateTreeRestAdapterApi
     */
    public getSimplified(requestParameters: LoadAffiliateTreeRestAdapterApiGetSimplifiedRequest = {}, options?: any) {
        return LoadAffiliateTreeRestAdapterApiFp(this.configuration).getSimplified(requestParameters.fullName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LoadAffiliateTreeRestAdapterApi
     */
    public getTop(options?: any) {
        return LoadAffiliateTreeRestAdapterApiFp(this.configuration).getTop(options).then((request) => request(this.axios, this.basePath));
    }
}
