// @mui
import { Container, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

import { MotionInView, varFade } from '../../components/animate';
import Image from '../../components/Image';

// components
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  padding: theme.spacing(8, 0),
  backgroundColor: theme.palette.grey[900],
}));

const ContentStyle = styled('div')(({ theme }) => ({
  textAlign: 'center',
  position: 'relative',
  marginBottom: theme.spacing(10),
  [theme.breakpoints.up('md')]: {
    height: '100%',
    marginBottom: 0,
    textAlign: 'left',
    display: 'inline-flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
}));

// ----------------------------------------------------------------------

export default function Affiliate() {
  const { t } = useTranslation();
  return (
    <RootStyle>
      <Container sx={{ position: 'relative' }}>

        <Image
          visibleByDefault
          disabledEffect
          alt="image shape"
          src="/images/partnership-program.svg"
          sx={{
            top: 0,
            right: 0,
            bottom: 0,
            width: 720,
            height: 720,
            // opacity: 0.48,
            my: -1,
            mx: -10,
            position: { 
              xs: 'initial',
              md: 'absolute'
           },
            display: { xs: 'contents', md: 'block' },
          }}
        />

        <Grid container spacing={10} direction="row-reverse" justifyContent="space-between">
          <Grid item xs={12} md={6} sx={{ position: 'relative' }}>
            <MotionInView
              threshold={0.5}
              variants={varFade().inDown}
              sx={{ top: 0, left: 0, position: 'absolute' }}
             />
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <ContentStyle sx={{ textAlign: 'left' }}>
            <MotionInView variants={varFade().inUp}>
              <Typography variant="h3" sx={{ mb: 3, color: 'common.white' }}>
                {t('landingPage.affiliateProgram.title')}
              </Typography>
            </MotionInView>

            <MotionInView variants={varFade().inUp}>
              <Typography sx={{ color: 'common.white', mb: 5 }}>
                {t('landingPage.affiliateProgram.description')}
              </Typography>
              <Container>
              <ul>
                <li>
                  <Typography sx={{
                    color: 'common.white',
                  }}>
                    {t('landingPage.affiliateProgram.firstLineCommission')}
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography sx={{
                    color: 'common.white',
                  }}>
                    {t('landingPage.affiliateProgram.secondLineCommission')}
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography sx={{
                    color: 'common.white',
                  }}>
                    {t('landingPage.affiliateProgram.thirdLineCommission')}
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography sx={{
                    color: 'common.white',
                  }}>
                    {t('landingPage.affiliateProgram.extraOrdinary')}
                  </Typography>
                </li>
                <br />
                <li>
                  <Typography sx={{
                    color: 'common.white',
                  }}>
                    {t('landingPage.affiliateProgram.dirrectReferrals')}
                  </Typography>
                </li>
              </ul>
              </Container>
            </MotionInView>
          </ContentStyle>
        </Grid>
      </Container>
    </RootStyle>
  );
}
