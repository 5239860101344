// @mui
import { Container, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { MotionInView, varFade } from '../../components/animate';

// components
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  paddingTop: theme.spacing(15),
  [theme.breakpoints.up('md')]: {
    paddingBottom: theme.spacing(15),
  },
}));

// ----------------------------------------------------------------------

export default function About() {
  const { t } = useTranslation();

  return (
    <RootStyle>
      <div className="about-us-wrapper">
        <Container>
          <MotionInView variants={varFade().inUp}>
            <Typography
              variant="h2"
              sx={{
                color: 'common.white',
              }}
            >
              {t('landingPage.aboutUs.title')}
            </Typography>
          </MotionInView>

          <MotionInView variants={varFade().inDown}>
            <div className="horizontal-line" />
            <Typography component="div" variant="overline" sx={{ mb: 2, color: 'text.disabled' }} />
          </MotionInView>

          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            spacing={{ xs: 8, md: 3 }}
          >
            <Grid item xs={12} md={5} />

            <Grid item xs={12} md={7}>
              <MotionInView
                variants={varFade().inUp}
                sx={{
                  mb: { xs: 3, md: 0 },
                }}
              >
                <Container>
                  <ul>
                    <li>
                      <Typography
                        sx={{
                          color: 'common.white',
                        }}
                      >
                        {t('landingPage.aboutUs.descriptionFirst')}
                      </Typography>
                    </li>
                    <br />
                    <li>
                      <Typography
                        sx={{
                          color: 'common.white',
                        }}
                      >
                        {t('landingPage.aboutUs.descriptionSecond')}
                      </Typography>
                    </li>
                    <br />
                    <li>
                      <Typography
                        sx={{
                          color: 'common.white',
                        }}
                      >
                        {t('landingPage.aboutUs.descriptionThird')}
                      </Typography>
                    </li>
                    <br />
                    <li>
                      <Typography
                        sx={{
                          color: 'common.white',
                        }}
                      >
                        {t('landingPage.aboutUs.descriptionFour')}
                      </Typography>
                    </li>
                    <br />
                    <li>
                      <Typography
                        sx={{
                          color: 'common.white',
                        }}
                      >
                        {t('landingPage.aboutUs.descriptionFive')}
                      </Typography>
                    </li>
                    <br />
                    <li>
                      <Typography
                        sx={{
                          color: 'common.white',
                        }}
                      >
                        {t('landingPage.aboutUs.descriptionSix')}
                      </Typography>
                    </li>
                  </ul>
                </Container>
              </MotionInView>
            </Grid>
          </Grid>
        </Container>
      </div>
    </RootStyle>
  );
}
