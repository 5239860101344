import { getAuthConfiguration } from './auth';
import {
  ManageReferralBonusesRestAdapterApi,
  ManageReferralBonusesRestAdapterApiIncomeHistoryRequest,
  ManageReferralBonusesRestAdapterApiReinvestHistoryRequest,
  ManageReferralBonusesRestAdapterApiReinvestRequest,
  PageBonusIncomeRecord,
  PageBonusReinvestRecord,
  ReferralBonusStatisticResponse,
} from './generated/client';

export const reinvestBonuses = async (
  requestParameters: ManageReferralBonusesRestAdapterApiReinvestRequest
): Promise<object> => {
  const conf = getAuthConfiguration();
  const api = new ManageReferralBonusesRestAdapterApi(conf);

  return (await api.reinvest(requestParameters)).data;
};

export const getTotalStatistic = async (): Promise<ReferralBonusStatisticResponse> => {
  const conf = getAuthConfiguration();
  const api = new ManageReferralBonusesRestAdapterApi(conf);

  return (await api.totalStatistic()).data;
};

export const getReinvestHistory = async (
  requestParameters: ManageReferralBonusesRestAdapterApiReinvestHistoryRequest
): Promise<PageBonusReinvestRecord> => {
  const conf = getAuthConfiguration();
  const api = new ManageReferralBonusesRestAdapterApi(conf);

  return (await api.reinvestHistory(requestParameters)).data.pagedData;
};

export const getIncomeHistory = async (
    requestParameters: ManageReferralBonusesRestAdapterApiIncomeHistoryRequest
  ): Promise<PageBonusIncomeRecord> => {
    const conf = getAuthConfiguration();
    const api = new ManageReferralBonusesRestAdapterApi(conf);
  
    return (await api.incomeHistory(requestParameters)).data.pagedData;
  };
