// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  newPassword: path(ROOTS_AUTH, '/new-password'),
  verify: path(ROOTS_AUTH, '/verify'),
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
  aboutUs: '/about-us'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  main: {
    root: path(ROOTS_DASHBOARD, '/main'),
  },
  activeTrading: {
    root: path(ROOTS_DASHBOARD, '/active-trading'),
  },
  profile: {
    root: path(ROOTS_DASHBOARD, '/profile'),
  },
  vip: {
    root: path(ROOTS_DASHBOARD, '/vip'),
  },
  news: {
    root: path(ROOTS_DASHBOARD, '/news'),
    detail: path(ROOTS_DASHBOARD, '/news/:newsId/:title'),
  },
  investment: {
    root: path(ROOTS_DASHBOARD, '/investment'),
  },
  withdraw: {
    root: path(ROOTS_DASHBOARD, '/withdraw'),
  },
  reinvest: {
    root: path(ROOTS_DASHBOARD, '/reinvest'),
  },
  settings: {
    root: path(ROOTS_DASHBOARD, '/settings'),
  },
  invite: {
    root: path(ROOTS_DASHBOARD, '/invite'),
  },
  affiliate: {
    root: path(ROOTS_DASHBOARD, '/affiliate'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
