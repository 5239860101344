import { useRef, useState } from 'react';
// @mui
import { Button, ListItemText, MenuItem, Stack } from '@mui/material';
// hooks
import useLocales from '../../hooks/useLocales';
// components
import { useTranslation } from 'react-i18next';
import Image from '../../components/Image';
import MenuPopover from '../../components/MenuPopover';

// ----------------------------------------------------------------------

export default function LanguagePopover() {
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { allLang, currentLang, onChangeLang } = useLocales();
  const { t } = useTranslation();

  return (
    <>
      <Button
        ref={anchorRef}
        onClick={() => setOpen(true)}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && { bgcolor: 'action.selected' }),
        }}
      >
        <Image
          disabledEffect
          src={currentLang.icon}
          alt={currentLang.label}
          sx={{ width: 40, height: 28 }}
        />
      </Button>

      <MenuPopover open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current}>
        <Stack spacing={0.5} sx={{ p: 1 }}>
          {allLang.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang.value}
              onClick={() => {
                onChangeLang(option.value);
                setOpen(false);
              }}
              sx={{ height: 40, borderRadius: 1, px: 1 }}
            >
              <Image
                disabledEffect
                alt={option.label}
                src={option.icon}
                sx={{ mr: 2, width: 40, height: 28 }}
              />

              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {t(`language.${option.value}`)}
              </ListItemText>
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  );
}
