import { getAuthConfiguration } from './auth';
import {
  CurrentBalanceHistoryDataPoint,
  IndexDistribution,
  LoadClientDashboardRestAdapterApi,
  LoadClientDashboardRestAdapterApiLoadBalanceHistoryRequest,
  LoadClientDashboardRestAdapterApiLoadBonusIncomeRequest,
  LoadClientDashboardRestAdapterApiLoadHoldIndexDistributionByPositionTypeRequest,
  LoadClientDashboardRestAdapterApiLoadPaymentsRequest,
  LoadClientDashboardRestAdapterApiLoadPayoutsByClientIdAndStatusRequest,
  LoadClientDashboardRestAdapterApiLoadShortIndicesRequest,
  MonthlyData,
  PagePaymentDTO,
  PagePayoutDTO,
  PageShortIndexDTO, PaymentHistoryDataPoint, UserDTO
} from './generated/client';
import { TotalBalanceStatusResponse } from './generated/client/model/total-balance-status-response';

export type BalanceHistoryType = {
  [key: string]: CurrentBalanceHistoryDataPoint;
};

export const getTotalReferrals = async (): Promise<number> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadTotalReferrals()).data;
};

export const getTotalInvested = async (): Promise<TotalBalanceStatusResponse> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadTotalInvested()).data;
};

export const getShortIndices = async (
  requestParameters: LoadClientDashboardRestAdapterApiLoadShortIndicesRequest
): Promise<PageShortIndexDTO> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadShortIndices(requestParameters)).data.pagedData;
};

export const getShortIndexDistribution = async (): Promise<Array<IndexDistribution>> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadShortIndexDistribution()).data.indexDistribution;
};

export const getProfile = async (): Promise<UserDTO> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadProfile()).data;
};

export const getPayouts = async (
  requestParameters: LoadClientDashboardRestAdapterApiLoadPayoutsByClientIdAndStatusRequest
): Promise<PagePayoutDTO> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadPayoutsByClientIdAndStatus(requestParameters)).data.pagedData;
};

export const getPayments = async (requestParameters: LoadClientDashboardRestAdapterApiLoadPaymentsRequest): Promise<PagePaymentDTO> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadPayments(requestParameters)).data.pagedData;
};

export const getHoldIndexDistribution = async (
  requestParameters: LoadClientDashboardRestAdapterApiLoadHoldIndexDistributionByPositionTypeRequest
): Promise<Array<IndexDistribution>> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadHoldIndexDistributionByPositionType(requestParameters)).data
    .indexDistribution;
};

export const getCurrentBalance = async (): Promise<CurrentBalanceHistoryDataPoint> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadCurrentBalance()).data;
};

export const getProfitsFromReferrals = async (
  requestParameters: LoadClientDashboardRestAdapterApiLoadBonusIncomeRequest
): Promise<Array<MonthlyData>> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadBonusIncome(requestParameters)).data.totalIncome;
};

export const getBalanceHistory = async (
  requestParameters: LoadClientDashboardRestAdapterApiLoadBalanceHistoryRequest
): Promise<BalanceHistoryType> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadBalanceHistory(requestParameters)).data;
};

export const getBalanceHistoryByTrade = async (): Promise<{
  [key: string]: { [key: string]: PaymentHistoryDataPoint };
}> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadBalanceHistoryByTrades()).data;
};

export const getAnnalInvested = async (): Promise<{ [key: string]: number }> => {
  const conf = getAuthConfiguration();
  const api = new LoadClientDashboardRestAdapterApi(conf);

  return (await api.loadAnnualInvested()).data;
};
